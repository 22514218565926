.modalBackdrop {
    position: fixed;
    // position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    background-color: #000c;
    width: 100vw;
    height: 0;
    z-index: 9999;
    overflow-y: auto;
    overflow-x: hidden;

    &.modalOpen {
        min-height: 100vh;
        height: 100%;
    }

    .modalContainer {
        display: flex;
        width: 100%;
        max-width: 1000px;
        min-width: 300px;
        height: max-content;
        padding: 5rem 0;

        @media screen and (max-width: 500px) {
            padding-bottom: 0;
            margin-top: auto;
        }

        .modalContent {
            display: flex;
            flex-direction: column;
            height: max-content;
            background-color: #fff;
            border-radius: 30px;
            padding: 4rem;
            position: relative;
            width: 100%;

            @media screen and (max-width: 500px) {
                padding: 2rem;
                padding-bottom: 150px;
                border-radius: 20px 20px 0 0;
            }

            .modalHeader {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-bottom: 2rem;

                .modalHeaderTopic {
                    font-size: 2rem;

                    @media screen and (max-width: 500px) {
                        font-size: 1.5rem;
                    }
                }

                .modalHeaderBar {
                    margin-top: 15px;
                    width: 220px;
                    height: 6px;
                    background: #339f00;
                    border-radius: 10px;

                    @media screen and (max-width: 500px) {
                        width: 120px;
                        height: 4px;
                    }
                }
            }

            .modalFooter {
                display: flex;
                justify-content: center;
                margin-top: auto;
                padding-top: 1rem;

                .filterFooterBtn {
                    background-color: #339f00;
                    font-family: "Kanit", sans-serif;
                    font-size: 1.5rem;
                    padding: 0.5rem 3rem;
                    color: #fff;
                    border-radius: 10px;
                    cursor: pointer;

                    &,
                    &:focus,
                    &:hover,
                    &:hover:focus {
                        border: none;
                        outline: none;
                    }
                }
            }

            .modalContentClose {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 20px;
                top: 20px;
                font-size: 2rem;
                width: 40px;
                height: 40px;
                line-height: 1;
                cursor: pointer;

                @media screen and (max-width: 350px) {
                    right: 10px;
                    top: 10px;
                }
            }
        }
    }
}
