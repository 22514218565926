.headerText {
    text-align: center;
    margin: 2rem 0;
    font-weight: 700;
    font-size: 100px;
    background: linear-gradient(180deg, #fff 0%, #000 64.06%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @media screen and (max-width: 1000px) {
        font-size: 80px;
    }

    @media screen and (max-width: 750px) {
        font-size: 65px;
    }

    @media screen and (max-width: 600px) {
        font-size: 55px;
    }

    @media screen and (max-width: 500px) {
        font-size: 45px;
    }

    @media screen and (max-width: 450px) {
        font-size: 35px;
    }
}

.imgBox {
    width: 100%;
    max-width: 250px;
    height: 170px;
    position: relative;

    .img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: contain;
    }

    .imgTemp {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition-duration: 0.5s;
        border-radius: 10px;
        object-fit: contain;

        &.imgTempLoaded {
            opacity: 0;
        }
    }
}

.flexColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    height: 100%;
}

.lable {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;

    @media screen and (max-width: 900px) {
        font-size: 1.5rem;
    }
}

.lableSecondary {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;

    @media screen and (max-width: 1000px) {
        font-size: 1.2rem;
    }
}

.lableRemark {
    font-size: 0.8rem;
    text-align: center;
    font-weight: 300;
}

.topAuto {
    margin-top: auto;
}

.dashSpan {
    height: 4px;
    width: 20px;
    background-color: #000;
    margin: 20px 0;
}

.lSpan {
    margin: 0.5rem 0;
    font-size: 24px;
    font-weight: 500;

    @media screen and (max-width: 1000px) {
        font-size: 20px;
    }
}

.mSpan {
    margin: 1rem 0;
    font-size: 20px;
    font-weight: 500;

    @media screen and (max-width: 1000px) {
        font-size: 18px;
    }
}

.sSpan {
    margin: 1rem 0;
    font-size: 18px;
    font-weight: 500;
}

.line {
    height: 1px;
    width: 100%;
    background-color: #cfcfcf;
    margin: 1.5rem 0;
}

.link {
    font-weight: 400;
    text-align: center;
    color: #3084ff;
    cursor: pointer;
}

.scrollContrainer {
    display: flex;
    justify-content: center;
    padding: 0 0 6rem;
    position: relative;

    @media screen and (max-width: 1390px) {
        justify-content: flex-start;
        padding: 3rem 6rem 6rem;
    }
}

.loadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carTypeSpan {
    padding: 0.5rem 2rem;
    border-radius: 10px;
    margin: 1rem 0;

    &.typeICE {
        color: #ffb800;
        border: 1px solid #ffb800;
    }
    &.typeBEV {
        color: #339f00;
        border: 1px solid #339f00;
    }
    &.typePHEV {
        color: #0d6efd;
        border: 1px solid #0d6efd;
    }
}

.mainTopic {
    font-weight: 500;
    font-size: 36px;

    @media screen and (max-width: 1000px) {
        font-size: 30px;
    }

    @media screen and (max-width: 700px) {
        font-size: 28px;
    }
}

.secondaryTopic {
    font-weight: 500;
    font-size: 30px;

    @media screen and (max-width: 1000px) {
        font-size: 26px;
    }

    @media screen and (max-width: 700px) {
        font-size: 22px;
    }
}

.stickyBox {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.stickyConstent {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 105px;
    width: 300px;
    background-color: #000;
    color: #fff;
    padding: 1rem;
    opacity: 0;

    &.stickyShow {
        opacity: 1;
    }

    .lableConstent {
        font-size: 1.2rem;
    }

    .lableOverflow {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
