.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000ee;
    width: 0;
    height: 100vh;
    z-index: 9999;
    overflow: hidden;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(1, 0, 0, 0);

    &.modalOpen {
        width: 100vw;
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0, 0, 0, 1);

        .modalContent {
            @for $i from 1 through 6 {
                & > div:nth-child(#{$i}),
                & > a:nth-child(#{$i}) {
                    transform: translateY(0);
                    transition-duration: 0.3s;
                    transition-timing-function: cubic-bezier(0, 0, 1, 1);
                    transition-delay: #{$i/5}s;
                    opacity: 1;
                }
            }
        }
    }

    .modalContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: fit-content;
        max-width: 1000px;
        min-width: 300px;
        height: max-content;
        padding: 2rem;

        .navbarItemLogo {
            width: fit-content;
            max-height: 90px;

            .navbarItemLogoImg {
                width: 100px;
                height: 100px;
                object-fit: contain;
            }
        }

        .modalContent {
            display: grid;
            grid-gap: 1rem;
            justify-content: center;
            width: fit-content;
            grid-template-columns: repeat(2, 100px);

            .modalContentHome {
                grid-column: span 2;
                opacity: 0;
                transform: translateY(10px);

                .navbarItemHome {
                    width: 100%;
                    height: 100px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: #fff;
                    border-radius: 10px;
                    overflow: hidden;
                    padding: 1rem;
                    color: #3f3f3f;

                    & > i {
                        font-size: 2rem;
                    }

                    & > span {
                        padding-top: 0.5rem;
                    }

                    &.active {
                        box-shadow: inset 0 0 0 3px #fff, inset 0 0 0 8px #339f00;

                        & > i {
                            color: #339f00;
                        }
                    }
                }
            }

            .navbarItem {
                width: 100%;
                height: 100px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                border-radius: 10px;
                overflow: hidden;
                padding: 1rem;
                color: #3f3f3f;
                opacity: 0;
                transform: translateY(10px);

                & > i {
                    font-size: 2rem;
                }

                & > span {
                    padding-top: 0.5rem;
                }

                &.active {
                    box-shadow: inset 0 0 0 3px #fff, inset 0 0 0 8px #339f00;

                    & > i {
                        color: #339f00;
                    }
                }
            }

            .modalContentClose {
                grid-column: span 2;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
                transform: translateY(10px);

                .navbarItemClose {
                    width: 50%;
                    height: 50px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: #fff;
                    border-radius: 10px;
                    overflow: hidden;
                    padding: 1rem;
                    color: #ff0000;
                    line-height: 1;

                    & > i {
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}
