.menu {
    position: fixed;
    top: 128px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    z-index: 9999;
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    backdrop-filter: blur(0px); /* Blur effect */
}

.link {
    margin: 10px 0;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #828282;
    text-decoration: none;
    padding: 0 10px;
    // border-left: 3px solid #007bff;
    &.active {
        // border-left: 3px solid #339f00;
        color: #339f00;
    }
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .menu {
        top: 14.4%;
    }
}
@media (max-width: 900px) {
    .menu {
        top: 13.4%;
    }
}
