.listBox {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    max-width: 360px;
    width: 100%;
    height: 100%;

    .listImgBox {
        position: relative;
        padding: 0 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 160px;

        @media screen and (max-width: 1200px) {
            height: 140px;
        }

        .listImg {
            height: 160px;
            width: 100%;
            object-fit: scale-down;
            overflow: hidden;
            border-radius: 20px;
        }
    }

    .listContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
        border-radius: 36px;
        margin-top: -70px;
        padding: 90px 25px 25px;
        height: 100%;

        // @media screen and (max-width: 1200px) {
        //     margin-top: -p0px;
        // }

        .listContentBrand {
            font-size: 1.5rem;
            font-weight: 500;
            text-align: center;

            @media screen and (max-width: 1000px) {
                font-size: 1.2rem;
            }
        }

        .listContentModel {
            font-size: 1.2rem;
            text-align: center;

            @media screen and (max-width: 1000px) {
                font-size: 1rem;
            }
        }

        .listContentPrice {
            font-weight: 300;
            text-align: center;
        }

        .listContentBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 10px;
            cursor: pointer;
            border-radius: 30px;
            line-height: 1;
            font-family: "Kanit", sans-serif;
            white-space: nowrap;

            &,
            &:focus,
            &:hover,
            &:hover:focus {
                border: none;
                outline: none;
            }

            &.listBtnDetail {
                color: #fff;
                background-color: #339f00;
            }

            &.listBtnCompare {
                color: #339f00;
                border: 1px solid #339f00 !important;
                background-color: #fff;

                & > i {
                    margin-right: 10px;
                }
            }
        }
    }
}
