.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 576px) {
        max-width: 540px;
    }

    @media (min-width: 768px) {
        max-width: 720px;
    }

    @media (min-width: 992px) {
        max-width: 960px;
    }

    @media (min-width: 1200px) {
        max-width: 1140px;
    }

    @media (min-width: 1400px) {
        max-width: 1320px;
    }
}

.page {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 100vw;
}

.rootStyle {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow: hidden;
    padding-top: 105px;
    position: relative;

    @media screen and (max-width: 950px) {
        padding-top: 85px;
    }

    @media screen and (max-width: 700px) {
        padding-top: 65px;
    }
}
