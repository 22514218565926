.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    background-color: #000c;
    width: 100vw;
    height: 0;
    z-index: 9999;
    overflow-y: auto;
    overflow-x: hidden;

    &.modalOpen {
        min-height: 100vh;
        height: 100%;
    }

    .modalContainer {
        display: flex;
        width: 100%;
        max-width: 800px;
        min-width: 300px;
        padding: 5rem 0;
        height: 100vh;

        &.maxWidth{
            max-width: 1050px;
        }

        .modalContent {
            display: flex;
            flex-direction: column;
            height: max-content;
            background-color: #fff;
            border-radius: 30px;
            padding: 1rem;
            position: relative;
            width: 100%;
            height: 100%;

            .modalFooter {
                display: flex;
                justify-content: center;
                margin-top: auto;
                padding-top: 1rem;

                .listBox {
                    width: 100px;
                    height: 100px;
                    overflow-x: auto;
                    overflow-y: hidden;
                    border-radius: 5px;
                    border: 2px solid transparent;

                    &:not(:first-child) {
                        margin-left: 1rem;
                    }

                    &.active {
                        border: 2px solid #339f00;
                    }

                    .listBoxImg {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        cursor: pointer;
                    }
                }
            }

            .modalContentImgBox {
                width: 100%;
                height: 100%;
                border-radius: 10px;
                overflow: hidden;

                .modalContentImg {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .modalContentClose {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 20px;
                top: 20px;
                font-size: 2rem;
                width: 40px;
                height: 40px;
                line-height: 1;
                cursor: pointer;
                background-color: #fff;
                border-radius: 50%;
                box-shadow: 0 0 0 2px #141414;
            }
        }
    }
}
