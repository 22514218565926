.container {
    padding: 3rem 7rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .header_container {
        p {
            color: #828282;
            font-size: 54px;
            font-weight: 400;
        }
        span {
            font-size: 30px;
            color: #333333;
        }
    }
    .vehicle_info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .vehicle_image_container {
            width: 400px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .vehicle_image_qrCode_section_container {
            width: 500px;
            overflow: hidden;
            border-radius: 8px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .vehicle_info_content {
            display: flex;
            flex-direction: column;
            width: 60%;
            gap: 1.5rem;
            h3 {
                font-size: 30px;
                font-weight: 400;
            }
            p {
                color: #333333;
                font-size: 20px;
                font-weight: 400;
            }
            span {
                font-size: 18px;
                color: #828282;
            }
        }
    }
    .content_mobile_container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 2rem 7rem;

        .more_infomation_vehicle_info {
            display: flex;
            flex-direction: row;
            height: 50vh;
            gap: 50px;

            .vehicle_info_content {
                display: flex;
                flex-direction: column;
                width: 50%;
                gap: 1.5rem;
                h3 {
                    font-size: 30px;
                    font-weight: 400;
                }
                p {
                    color: #333333;
                    font-size: 20px;
                    font-weight: 400;
                }
                span {
                    font-size: 18px;
                    color: #828282;
                }
            }
        }
        .other_convenience {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            h3 {
                font-size: 30px;
                font-weight: 400;
            }
            .other_convenience_content {
                display: flex;
                flex-direction: row;
                gap: 3rem;
                .other_convenience_content_item {
                    padding: 20px;
                    background: #f8f9fa;
                    border-radius: 1rem;
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    .other_convenience_image_container {
                        width: 50px;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    p {
                        color: #333333;
                        font-size: 24px;
                        font-weight: 400;
                    }
                    span {
                        font-size: 16px;
                        color: #333333;
                    }
                }
            }
        }
    }
}

.container_more_infomation_vehicle {
    display: flex;
    flex-direction: row;
    width: 50%;
    position: relative;
}

.card {
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border-top-right-radius: 40%;
    width: 210px;
    height: 370px;
    overflow: hidden;

    background: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

    .image {
        width: 90px;
        padding: 20px;
        img {
            width: 100%;
            height: 100%;
            filter: invert(35%) sepia(99%) saturate(1530%) hue-rotate(40deg) brightness(101%) contrast(101%);
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        padding-right: 2rem;

        p {
            font-size: 18px;
            color: #339f00;
            font-weight: 500;
            text-align: center;
        }

        span {
            margin: 0.2rem 0;
        }
    }
}

.card1 {
    top: 0;
    left: 0;
}

.card2 {
    top: 40px;
    left: 170px;
}

.card3 {
    top: 80px;
    left: 350px;
}
