.footerContainer {
    display: flex;
    flex-direction: column;
    background-color: #000;
    color: #fff;

    .footerLowwerContainer {
        display: flex;
        background-color: #141414;
    }

    .footerLowwer {
        display: flex;

        @media screen and (max-width: 900px) {
            padding: 0 2rem;
        }

        @media screen and (max-width: 540px) {
            flex-direction: column;
            padding: 0 1rem;
        }

        .footerLowwerItem {
            display: flex;
            align-items: center;
            flex: 2;
            padding: 10px 0;

            &:not(:first-child) {
                flex: 1;
                justify-content: center;
            }

            .footerLowwerIcon {
                padding: 5px 10px;
                cursor: pointer;
            }

            @media screen and (max-width: 900px) {
                &:first-child {
                    align-items: flex-start;
                    flex-direction: column;
                }
            }
        }
    }

    .footerSecondary {
        font-size: 14px;
        padding-left: 5px;
        color: #656565;

        @media screen and (max-width: 900px) {
            padding-left: 0;
        }
    }

    .footerUpper {
        display: flex;
        padding: 2rem 0;

        @media screen and (max-width: 900px) {
            padding: 2rem;
            flex-flow: wrap;
        }

        @media screen and (max-width: 640px) {
            flex-direction: column;
        }

        @media screen and (max-width: 540px) {
            padding: 2rem 1rem;
        }

        .footerUpperItem {
            display: flex;
            flex-direction: column;
            flex: 3;

            &:not(:first-child) {
                padding-left: 4rem;
            }

            &:first-child,
            &:last-child {
                flex: 2;
            }

            .footerUpperTopic {
                font-size: 20px;
                font-weight: 500;
                min-width: 185px;
                width: fit-content;
                border-bottom: 5px solid #339f00;
            }

            .footerContact {
                color: #fff;
            }

            .footerAddress {
                margin-bottom: 0;
            }

            .footerUpperNewsBox {
                display: flex;
                flex-direction: column;
                margin-top: 1rem;
            }

            @media screen and (max-width: 900px) {
                padding-left: 0 !important;

                &:first-child {
                    width: 100%;
                    flex: auto;
                    margin-bottom: 1rem;
                }

                &:last-child {
                    padding-left: 2rem !important;
                }
            }

            @media screen and (max-width: 640px) {
                padding-left: 0 !important;
                margin-bottom: 1rem !important;
                &:last-child {
                    padding-left: 0 !important;
                }
            }
        }

        .footerUpperLogo {
            width: 110px;
            height: 65px;
            margin-bottom: 10px;
        }
    }
}

.newsContent {
    display: flex;

    &:not(:last-child) {
        padding-bottom: 1rem;
    }

    .newsContentImgBox {
        width: 90px;
        height: 60px;
        border-radius: 10px;
        overflow: hidden;

        .newsContentImg {
            width: 100%;
            height: 100%;
        }
    }

    .newsContentDetailBox {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-left: 1rem;

        .newsContentDetail {
            padding-left: 1.5rem;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}
