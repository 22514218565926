$background-color: white;
$font-primary-color: black;
$font-secondary-color: black;
$font-tertiary-color: #339f00;

$font-styles: "Kanit", sans-serif;

// breakpoints for responsive
$breakpoints-width: (
  mobile_portrait: (
    min: 320px,
    max: 480px,
  ),
  mobile_landscape: (
    min: 481px,
    max: 767px,
  ),
  tablets_portrait: (
    min: 768px,
    max: 1024px,
  ),
  tablets_landscape: (
    min: 768px,
    max: 1024px,
    orientation: landscape,
    // Add orientation condition here
  ),
  desktop: (
    min: 1025px,
    max: 1280px,
  ),
  large_desktop: (
    min: 1281px,
    max: 9999px,
    // Set an arbitrary high value
  )
  // Add more breakpoints as needed,,,,,,,
);

@mixin breakpoint($breakpoint, $direction: max) {
  @if map-has-key($breakpoints-width, $breakpoint) {
    $breakpoint-values: map-get($breakpoints-width, $breakpoint);
    $breakpoint-min: map-get($breakpoint-values, min);
    $breakpoint-max: map-get($breakpoint-values, max);
    $breakpoint-orientation: map-get(
      $breakpoint-values,
      orientation
    ); // Add orientation variable

    @if $direction == min {
      @media (min-width: $breakpoint-min) {
        @if $breakpoint-orientation {
          @media (#{$breakpoint-orientation}) {
            // Include orientation condition if defined
            @content;
          }
        } @else {
          @content;
        }
      }
    } @else {
      @media (max-width: $breakpoint-max) {
        @if $breakpoint-orientation {
          @media (#{$breakpoint-orientation}) {
            // Include orientation condition if defined
            @content;
          }
        } @else {
          @content;
        }
      }
    }
  } @else {
    @if $direction == min {
      @media (min-width: $breakpoint) {
        @content;
      }
    } @else {
      @media (max-width: $breakpoint) {
        @content;
      }
    }
  }
}
// /* Usage of the breakpoint mixin */
// /* Desktops (1281px and higher) */
// @include breakpoint(large_desktop) {
//     /* CSS for large desktops */
// }

// /* Laptops and Desktops (1025px to 1280px) */
// @include breakpoint(desktop) {
//     /* CSS for laptops and desktops */
// }

// /* Tablets, iPads (Portrait) (768px to 1024px) */
// @include breakpoint(tablets_portrait) {
//     /* CSS for tablets in portrait mode */
// }

// /* Tablets, iPads (Landscape) (768px to 1024px) */
// @include breakpoint(tablets_landscape) {
//     /* CSS for tablets in landscape mode */
// }

// /* Low-Resolution Tablets, Mobiles (Landscape) (481px to 767px) */
// @include breakpoint(mobile_landscape) {
//     /* CSS for mobiles in landscape mode */
// }

// /* Most of the Smartphones, Mobiles (Portrait) (320px to 480px) */
// @include breakpoint(mobile_portrait) {
//     /* CSS for mobiles in portrait mode */
// }

// Or use a custom value
// @include breakpoint(400px, max) {
//     width: 50%;

.justifyContentCenter {
  display: flex;
  justify-content: center;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.anTitle {
  font-family: $font-styles;
  color: #000000;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: center;
  margin: 20px 0px 20px 0px;
}

.anDivider {
  display: flex;
  justify-content: center;
  height: 8px;
  // width: 580px;
  width: 40%;
  left: 430px;
  top: 292px;
  border-radius: 0px;
  background: #000000;
}

.anContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint(mobile_portrait) {
    align-items: center;
    flex-direction: column;
  }

  @include breakpoint(mobile_landscape) {
    align-items: center;
    flex-direction: column;
  }

  //   @include breakpoint(large_desktop) {
  //     flex-direction: row;
  //   }

  //   @include breakpoint(desktop) {
  //     flex-direction: row;
  //   }

  //   @include breakpoint(tablets_portrait) {
  //     flex-direction: row;
  //   }

  //   @include breakpoint(tablets_landscape) {
  //     flex-direction: row;
  //   }
}

.anBlock {
  height: 32vh;
  width: 25%;
  border-radius: 10px;
  padding: 20px 25px 20px 25px;
  box-shadow: 0px 4px 20px 0px #0000000d;
  margin: 50px 20px 50px 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  // justify-content: center;

  @include breakpoint(mobile_portrait) {
    height: 32vh;
    width: 80%;
    margin: 10px 5px 10px 5px;
  }

  @include breakpoint(mobile_landscape) {
    height: 20vh;
    width: 70%;
    margin: 10px 5px 10px 5px;
  }

  @include breakpoint(tablets_landscape) {
    width: 50%;
    height: 32vh;
  }

  @include breakpoint(tablets_portrait) {
    height: 32vh;
  }

  @include breakpoint(desktop) {
    height: 32vh;
  }
}

.anImage {
  width: 100%;
  // height: 80%;
}

.anBlockContent {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  div {
    font-family: $font-styles;
    color: #000000;
    font-size: clamp(18px, 1.2vw, 18px);
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;

    &:hover {
      text-decoration: underline;
    }
  }
}

.anDate {
  font-family: $font-styles;
  color: #828282;
  // font-size: 14px;
  font-size: clamp(12px, 0.9vw, 14px);
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
}

.seeAll {
  font-family: $font-styles;
  color: #000000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 40px;
}

.pageContent {
  width: 100%;
  padding: 4rem 2rem 17rem 2rem;

  @media screen and (max-width: 900px) {
    padding: 3rem 0 5rem;
  }

  @media screen and (max-width: 750px) {
    padding: 1.5rem 0 5rem;
  }
}
