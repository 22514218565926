$background-color: white;
$font-primary-color: black;
$font-secondary-color: black;
$font-tertiary-color: #339f00;

.justifyContentCenter {
    display: flex;
    justify-content: center;
}

.mainBlock {
    display: flex;
    width: 100%;
    border-radius: 10px;
    padding: 20px 25px;
    box-shadow: 0 4px 20px 0 #0000000d;
    color: #000;

    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
}

.mainBlockLeft {
    width: 60%;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
}

.mainImage {
    width: 100%;
    height: 330px;
    object-fit: cover;
    border-radius: 5px;

    @media screen and (max-width: 950px) {
        height: 280px;
    }

    @media screen and (max-width: 750px) {
        height: 230px;
    }

    @media screen and (max-width: 650px) {
        height: 180px;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        height: auto;
    }
}

.mainBlockRight {
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    width: 40%;

    @media screen and (max-width: 600px) {
        width: 100%;
        padding-left: 20px;
    }

    div {
        font-size: 2.5rem;
        font-weight: 400;
        width: 100%;

        @media screen and (max-width: 950px) {
            font-size: 1.8rem;
        }

        @media screen and (max-width: 750px) {
            font-size: 1.4rem;
        }
    }

    span {
        color: #828282;
        font-weight: 300;
    }
}

.subContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-top: 3rem;
}

.subBlock {
    margin-left: 30px;
    margin-bottom: 2rem;
    width: calc((100% - 90px) / 3);
    border-radius: 10px;
    padding: 20px 25px;
    box-shadow: 0 4px 20px 0 #0000000d;
    color: #000;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    @media screen and (max-width: 1000px) {
        width: calc((100% - 60px) / 2);
    }

    @media screen and (max-width: 600px) {
        width: calc(100% - 30px);
    }

    .subBlockImg {
        width: 100%;
        border-radius: 5px;
    }
}

.subBlockContent {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    margin-top: 1rem;
    height: 100%;

    .subTextBox {
        font-size: 20px;
        font-weight: 400;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media screen and (max-width: 1100px) {
            font-size: 16px;
        }
    }

    span {
        margin-top: auto;
        color: #828282;
        font-weight: 300;
    }
}

.pageContent {
    width: 100%;
    padding: 4rem 0 7rem;

    @media screen and (max-width: 900px) {
        padding: 3rem 0 5rem;
    }

    @media screen and (max-width: 750px) {
        padding: 1.5rem 0 5rem;
    }
}
