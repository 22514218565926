.splide__pagination {
  li {
    .splide__pagination__page {
      background-color: #dee2e6;
    }
    .is-active {
      background-color: #adb5bd;
    }
  }
}
