.div-container-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
}
.div-content-left-section {
    padding: 30px;
    display: flex;
    flex-direction: column;
    width: 60%;
    p {
        color: #339f00;
        font-size: 40px;
        font-weight: 500;
        margin: 20px;
    }
    span {
        font-size: 18px;
        font-weight: 400;
        text-indent: 50px;
    }
}
.div-content-reference {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: flex-start;
    color: #339f00;
    border: 2px solid #339f00;
    padding: 10px;
    border-radius: 10px;
    margin-top: 20px;
    span {
        text-indent: 0px;
        font-size: 16px;
        font-weight: 400;
    }
}
.div-content-image {
    width: 40%;
    overflow: hidden;
    border-radius: 10px;
    img {
        width: 100%;
        height: 100%;
    }
}
