$background-color: white;
$font-primary-color: black;
$font-secondary-color: black;
$font-tertiary-color: #339F00;

$font-styles: "Kanit", sans-serif;

.justifyContentCenter{
    display: flex;
    justify-content: center;
}

.container{
    padding-left: 40px;
    padding-right: 40px;
}

.eventHeader {
    font-family: $font-styles;
    font-weight: 400;
    font-size: 48px;
    line-height: 1;
    color: #008000;
    margin: 40px 0px 60px 0px;
    display: flex;
    justify-content: center;
}

.breadcrumbsInActive{
    font-family: $font-styles;
    color: #727272;
    font-size: 1vw;
    font-style: 'normal';
    font-weight: 300;
}

.breadcrumbsActive{
    font-family: $font-styles;
    color: #000000;
    font-size: 1vw;
    font-style: 'normal';
    font-weight: 400;
}

.text{
    font-family: $font-styles;
    color: #000000;
    font-size: 1.1vw;
    font-style: 'normal';
    font-weight: 300;
    margin: 30px 20px 50px 20px;
    text-align: justify;

    img{
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        border-radius: 6px;
    }
}

.divider{
    display: flex;
    justify-content: center;
    height: 0.5px;
    width: 80%;
    left: 430px;
    border-radius: 4px;
    background: rgba(90, 90, 90, 0.5);
    margin: 30px 20px 50px 20px;
}

.galleryContainer{
    width: 90%;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: auto;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    padding: 0;
    list-style-type: none;
    overflow-x:hidden;
}

.galleryBlock{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    // background-color: red;   
    // border-color: #339F00;
    // border-style: solid;
    // border-width: 1px;

    .textOverlay{
        font-family: $font-styles;
        color: transparent;
        font-size: 1.4vw;
        font-style: 'normal';
        font-weight: 500;
        width: 90%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;

        &:hover {
            cursor: pointer;
            opacity: 1;
            background-color: #000000b5;
            color: #ffffff;
            transition-duration: 0.3s;
            letter-spacing: .1em;
            text-shadow: 0 -1px 0 #fff, 0 1px 0 #2e2e2e, 0 2px 0 #2c2c2c, 0 3px 0 #2a2a2a, 0 4px 0 #282828, 0 5px 0 #262626, 0 6px 0 #242424, 0 7px 0 #222, 0 8px 0 #202020, 0 9px 0 #1e1e1e, 0 10px 0 #1c1c1c, 0 11px 0 #1a1a1a, 0 12px 0 #181818, 0 13px 0 #161616, 0 14px 0 #141414, 0 15px 0 #121212, 0 22px 30px rgba(0,0,0,0.9);
        }
    }
}

.galleryPicture{
    width: 90%;
    height: auto;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 20px -5px rgba(166,166,166,1);
    -moz-box-shadow: 0px 0px 20px -5px rgba(166,166,166,1);
    box-shadow: 0px 0px 20px -5px rgba(166,166,166,1);
}

.pageContent {
    width: 100%;
    padding: 2rem 0 7rem;

    @media screen and (max-width: 900px) {
        padding: 3rem 0 5rem;
    }

    @media screen and (max-width: 750px) {
        padding: 1.5rem 0 5rem;
    }
}


