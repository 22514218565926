.pageContent {
    width: 100%;
    padding: 2rem 0 7rem;

    @media screen and (max-width: 900px) {
        padding: 3rem 0 5rem;
    }

    @media screen and (max-width: 750px) {
        padding: 1.5rem 0 5rem;
    }
}

.pageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.pageTopic {
    font-weight: 400;
    font-size: 48px;
    line-height: 1;
    color: #008000;
    margin: 2rem 0;
}

.topicLeft {
    font-size: 36px;
    width: 100%;
    margin-bottom: 2rem;
}

.imgSection {
    display: flex;
    width: 100%;
    justify-content: center;

    .imgTop {
        object-fit: cover;
        width: 50%;
        border-radius: 10px;
        pointer-events: none;

        &:nth-of-type(1) {
            margin-right: 10px;
        }

        &:nth-of-type(2) {
            margin-left: 10px;
        }
    }

    .imgTopSecondary {
        object-fit: cover;
        width: 100%;
        border-radius: 10px;
        pointer-events: none;
    }

    .imgTopThird {
        object-fit: cover;
        width: calc((100% - 40px) / 3);
        border-radius: 10px;
        pointer-events: none;

        &:not(:first-child) {
            margin-left: 20px;
        }
    }
}

.paraghapBox {
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    line-height: 1.8rem;
    margin-bottom: 2rem;
    font-weight: 300;
    align-self: flex-start;

    .paraghapBreak {
        margin-bottom: 1rem;
    }

    .paraghapTopicLeft {
        font-size: 30px;
        width: 100%;
        font-weight: 400;
    }

    .paraghapListCircle {
        list-style-type: circle;
    }

    .paraghapListNone {
        list-style-type: none;
    }
}

.paraghapKeyword {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5rem;
    align-self: flex-start;
    width: 100%;
    background-color: #fbfbfb;
    border-radius: 10px;
    color: #008000;

    .paraghapKeywordQuote {
        font-size: 60px;
    }

    .paraghapKeywordText {
        text-align: center;
        font-weight: 300;
        font-size: 30px;
        line-height: 48px;
        margin-bottom: 2rem;
    }
}

.textGreen {
    color: #339f00;
}

.textBold {
    font-weight: 500;
}

.sectionBreak {
    margin-bottom: 3rem;
}

.mixSection {
    display: flex;

    .imgMix {
        object-fit: cover;
        width: 30%;
        padding-right: 2rem;
    }

    & > *:not(:first-child) {
        padding-left: 2rem;
    }
}

.pageTopicSecondary {
    font-weight: 500;
    font-size: 64px;
}

.pageSpan {
    font-weight: 400;
    font-size: 40px;
}

.sectionButton {
    display: flex;
    justify-content: center;
}

.contentButton {
    width: 100%;
    color: #339f00;
    border: 2px solid #339f00;
    background-color: #ffff;
    border-radius: 10px;
    // max-width: 300px;
    padding: 1rem 2rem;
    white-space: nowrap;
    margin: 0 1rem;
    font-size: 1.5rem;
    cursor: pointer;
}

.boxImageContent {
    padding: 15px 0;
    height: 80vh;
    overflow: scroll;
    .imgContent {
        width: 100%;
        object-fit: contain;
    }
}

.pdfContent {
    width: 100%;
    height: 100%;
}

.videoSection {
    display: flex;
    justify-content: center;
    width: 100%;

    .videoContent {
        width: 50%;
        min-height: 300px;
    }
}

.textLinkNavigate {
    p {
        font-size: 26px;
        font-weight: 600;
        color: #68b984;
        display: inline-block;
        margin: 0;
        text-transform: uppercase;
    }
    p:after {
        display: block;
        content: "";
        border-bottom: solid 3px #ade792;
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
    }
    p:hover:after {
        transform: scaleX(1);
    }
}
