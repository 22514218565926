.sortFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    padding-top: 1rem;



    .sortFooterBtn {
        background-color: #339f00;
        font-family: "Kanit", sans-serif;
        font-size: 1.5rem;
        padding: 0.5rem 3rem;
        color: #fff;
        border-radius: 10px;
        cursor: pointer;

        @media screen and (max-width: 700px) {
            font-size: 1.2rem;
            padding: 5px 1.8rem;
        }

        @media screen and (max-width: 350px) {
            font-size: 1rem;
        }

        &,
        &:focus,
        &:hover,
        &:hover:focus {
            border: none;
            outline: none;
        }
    }
}

.sortItem {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;

    &:last-child {
        padding-bottom: 2.5rem;
    }

    .sortItemHeader {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 1rem;

        .sortItemHeaderText {
            background-color: #fff;
            padding-right: 10px;
            font-size: 1.5rem;
            z-index: 1;

            @media screen and (max-width: 500px) {
                font-size: 1.2rem;
            }
        }

        .sortItemHeaderLine {
            position: absolute;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #339f00;
        }
    }
}

.sortOption {
    display: flex;

    .sortOptionItem {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 30px;
        line-height: 1;
        border: 1px solid #ddd;

        @media screen and (max-width: 500px) {
            font-size: .8rem;
        }

        &:not(:last-child) {
            margin-right: 1rem;
        }

        &:hover {
            border: 1px solid #339f00;
        }

        & > i {
            margin-right: 10px;
        }

        &.sortOptionSelected {
            color: #339f00;
            border: 1px solid #339f00;
            background-color: #fff;
        }
    }
}

.clearAllSort {
    margin: 0 15px;
    cursor: pointer;
    color: #339f00;

    &.disabled {
        cursor: default;
        color: #a3a3a3;
        pointer-events: none;
    }
}
