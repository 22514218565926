.banner {
    background-image: url(../assets/nextGeneration/banner-next-gen.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    .containerBanner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .circle {
            width: 460px;
            height: 120px;
            background-color: rgba(255, 255, 255, 1);
            position: absolute;
            box-shadow: 0px 0px 154px 80px rgba(255, 255, 255, 1);
            filter: blur(90px);
        }

        .titles {
            font-size: 64px;
            line-height: 77.45px;
            background: linear-gradient(#a9a9a9, #1c1c1c);
            background-clip: text;
            color: transparent;
            z-index: 50;
        }

        .subtitles {
            font-size: 22px;
            line-height: 26.63px;
            z-index: 50;
            color: #5f5f5f;
        }

        @media screen and (max-width: 400px) {
            .titles {
                font-size: 50px;
            }
            .subtitles {
                font-size: 14px;
            }
        }
        @media screen and (max-width: 450px) {
            .titles {
                font-size: 50px;
            }
            .subtitles {
                font-size: 14px;
            }
        }
    }
}

.container {
    display: flex;
    gap: 8px;
    // padding-left: 250px;
    // padding-right: 250px;
    transform: translateY(-20px);
    font-size: 16px;

    .main {
        height: auto;
        width: 500px;
        min-width: 500px;
        transform: translateY(-40px);
        max-height: 400px;
    }

    .subtitles {
        margin-top: 60px;
        z-index: 50;

        span {
            color: #559b2d;
            font-size: 20px;
            line-height: 20px;
        }
    }

    .subtitles2 {
        margin-left: 20px;
    }

    .subtitles3 {
        text-indent: 40px;
    }

    .case {
        min-width: 250px;
        height: 140px;
        margin-left: 120px;
        transform: translateY(-60px);
    }
}

@media (max-width: 1000px) {
    .container {
        // padding-left: 25px;
        // padding-right: 25px;
        flex-direction: column;
        align-items: center;
        transform: translateY(0px);

        .main {
            width: 600px;
            min-width: 200px;
            transform: translateY(0px);
        }
        .case {
            margin-left: 0px;
            transform: translateY(0px);
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .subtitles2 {
            margin-left: 0px;
            margin-top: 0px;
        }
        .subtitles {
            margin-top: 10px;
        }
        .subtitles3 {
            margin-top: 10px;
        }
    }
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .container {
        // padding-left: 50px;
        // padding-right: 50px;

        .main {
            width: 400px;
            min-width: 400px;
            transform: translateY(-30px);
        }
        .case {
            min-width: 200px;
            height: 140px;
            margin-left: 70px;
            transform: translateY(-40px);
        }
        .subtitles2 {
            margin-top: 10px;
        }
    }
}

.containerArea {
    padding-left: 250px;
    padding-right: 250px;
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .containerArea {
        padding-left: 50px;
        padding-right: 50px;
    }
}
@media (max-width: 1000px) {
    .containerArea {
        padding-left: 25px;
        padding-right: 25px;
    }
}
@media (min-width: 1200px) and (max-width: 1500px) {
    .containerArea {
        padding-left: 150px;
        padding-right: 150px;
    }
}

.bannerCase {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    .backgroundCase {
        background-image: url(../assets/nextGeneration/case.png);
        background-size: 110%;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        position: absolute;
        filter: blur(10px) opacity(70%);
    }

    .caseImage {
        height: 100%;
        z-index: 90;
    }
}

.textContainer {
    margin-top: 10px;
    font-size: 12px;
}

@media (max-width: 600px) {
    .bannerCase {
        height: 100%;

        .backgroundCase {
            display: none;
        }
        .caseImage {
            width: 100%;
            height: 140px;
        }
    }
}

@media (max-width: 600px) {
    .textContainer {
        margin-top: 10px;
        font-size: 10px;
    }
}

.interestTopic {
    margin-top: 30px;

    .title {
        font-size: 28px;
        font-weight: 600;
    }
}

.cardImage {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    height: 160px;
    display: flex;
    align-items: flex-end;
}

.cardTitle {
    // background-color: black;
    background: linear-gradient(transparent, black);
    width: 100%;
    padding-left: 8px;
    padding-bottom: 4px;
    height: 35%;
    display: flex;
    font-size: 16px;
    align-items: flex-end;
}
