// .responsiveBg {
//     background-image: url('../../assets/detailnextgenerationautomotive/mobileApplicationInfo/bg_banner_oie_mobile.jpg');
//     @apply
//     h-[calc(100dvh-105px)] w-full bg-cover bg-center flex justify-center overflow-hidden
//     md:h-[calc(100dvh-85px)] sm:md:h-[calc(100dvh-65px)];
// }

// @media (min-width: 768px) {
//     .responsiveBg {
//         background-image: url('../../assets/detailnextgenerationautomotive/mobileApplicationInfo/bg_banner_oie.jpg');
//     }
// }
.responsiveBg {
    background-image: url("../../assets/detailnextgenerationautomotive/mobileApplicationInfo/bg_banner_oie_mobile.jpg");
    height: calc(100vh - 105px); /* เทียบกับ h-[calc(100dvh-105px)] */
    width: 100%; /* เทียบกับ w-full */
    background-size: cover; /* เทียบกับ bg-cover */
    background-position: center; /* เทียบกับ bg-center */
    display: flex; /* เทียบกับ flex */
    justify-content: center; /* เทียบกับ justify-center */
    overflow: hidden; /* เทียบกับ overflow-hidden */

    @media (min-width: 768px) {
        height: calc(100vh - 85px); /* เทียบกับ md:h-[calc(100dvh-85px)] */
    }

    @media (max-width: 768px) and (min-width: 640px) {
        height: calc(100vh - 65px); /* เทียบกับ sm:md:h-[calc(100dvh-65px)] */
    }
}

@media (min-width: 768px) {
    .responsiveBg {
        background-image: url("../../assets/detailnextgenerationautomotive/mobileApplicationInfo/bg_banner_oie.jpg");
    }
}