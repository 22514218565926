@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@200;300;400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  margin: 0;
  font-family: "Kanit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

*,
*::after,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
}

.on-modale-open {
  overflow: hidden;
}

ol {
  list-style-type: decimal;
}

li {
  margin-left: 0;
}