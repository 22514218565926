.navbarContainer {
    display: flex;
    height: 105px;
    position: fixed;
    width: 100vw;
    background-color: #fff;
    top: 0;
    box-shadow: inset 0 -5px 0 #339f00;
    z-index: 9999;

    @media screen and (max-width: 900px) {
        height: 85px;
    }

    @media screen and (max-width: 700px) {
        height: 65px;
    }

    .navbarItemSide {
        flex: 1;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding: 0 15px;
        padding-right: 7rem;
        height: 100%;

        &.left {
            justify-content: flex-end;
        }

        &.sm{
            padding-bottom: 5px;
            align-items: center;
        }
    }

    .navbarItemCenter {
        width: fit-content;
        height: 100%;
        border-bottom: 5px solid transparent;
        padding: 0 7rem;
        padding-right: 2rem;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        @media screen and (max-width: 900px) {
            padding: 0 1rem;
        }

        &.sm{
            align-items: center;
        }

        .navbarItemLogo {
            width: 120px;
            // height: 84px;

            @media screen and (max-width: 900px) {
                width: 100px;
                // height: 65px;
            }

            @media screen and (max-width: 700px) {
                width: 85px;
                // height: 50px;
            }
        }
    }

    .navbarItem {
        width: fit-content;
        height: fit-content;
        border-bottom: 5px solid transparent;
        padding: 0 2rem 1rem;
        display: flex;
        align-items: flex-end;
        font-size: 20px;
        color: #828282;
        position: relative;

        &.active {
            color: #339f00;
            font-weight: 500;
        }

        &:not(:first-child):before {
            content: "";
            position: absolute;
            left: 0;
            top: 6px;
            width: 1px;
            height: 20px;
            background-color: #c4c4c4;
        }

        @media screen and (max-width: 900px) {
            font-size: 1rem;
        }

        @media screen and (max-width: 700px) {
            padding: 0 1.2rem 1rem;
        }
    }

    .active {
        border-bottom: 5px solid #828282;
    }

    .navbarMenu{
        padding: 5px 10px;
        width: fit-content;
        height: fit-content;
        border: 1px solid #339f00;
        color: #339f00;
        border-radius: 5px;
    }
}
