.container {
	display: flex;
	margin-left: -2rem;

	@media screen and (max-width: 500px) {
		flex-direction: column;
		white-space: nowrap;

		& .lazyloadContainer {
			&:last-child {
				align-self: flex-end;
				margin-top: 20px;
			}
		}
	}
	& > div {
		margin-left: 2rem;
		display: flex;
		min-height: 100px;
	}

	&.containerSocondary {
		margin-top: 2rem;

		@media screen and (max-width: 500px) {
			margin-bottom: -20px;
		}
	}

	.lazyloadContainer {
		width: 50%;
		@media screen and (max-width: 500px) {
			width: 440px;
		}

		@media screen and (max-width: 414px) {
			width: 370px;
		}

		@media screen and (max-width: 375px) {
			width: 350px;
		}
	}
	.lazyloadContainerCenter {
		width: 100%;
		@media screen and (max-width: 500px) {
			width: 380px;
		}
	}
	.block1 {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		background-image: url("../../assets/carDetail/backdrop/engine.png");
		box-shadow: 0px 6px 20px #00000040;
		border-radius: 30px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		padding: 30px;
		color: #fff;

		@media screen and (max-width: 1024px) {
			border-radius: 0px 15px 15px 0px;
		}

		.enLeftBlockRow {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;
			padding-left: 25%;

			@media screen and (max-width: 500px) {
				padding-left: 1rem;
			}

			&:not(:first-child) {
				margin-top: 1.5rem;
			}
		}

		.enLeftBlockIcon {
			color: #ffffff;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 50px;
			height: 40px;

			@media screen and (max-width: 500px) {
				width: 35px;
				height: 30px;
			}

			// @media only screen and (min-width: 769px) and (max-width: 1024px) {
			//   width: 30%;
			//   justify-content: flex-end;
			//   margin-right: 30px;
			// }

			// @media only screen and (min-width: 481px) and (max-width: 768px) {
			//   width: 30%;
			//   justify-content: flex-end;
			//   margin-right: 20px;
			// }

			// @media only screen and (min-width: 320px) and (max-width: 480px) {
			//   width: 30%;
			//   justify-content: flex-end;
			//   margin-right: 20px;
			// }

			.enLeftIcon {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		.enLeftContents {
			display: flex;
			flex-direction: column;
			margin-left: 1.5rem;
		}

		.enLeftBlockTitltle {
			font-size: 18px;

			@media screen and (max-width: 1024px) {
				font-size: 16px;
			}

			@media screen and (max-width: 500px) {
				font-size: 12px;
			}
		}

		.enLeftBlockSubTitltle {
			font-weight: 500;
			font-size: 22px;

			@media screen and (max-width: 1024px) {
				font-size: 18px;
			}

			@media screen and (max-width: 500px) {
				font-size: 14px;
			}
		}
	}

	.block2 {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		background-image: url("../../assets/carDetail/backdrop/battery.png");
		box-shadow: 0px 6px 20px #00000040;
		border-radius: 30px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		padding: 20px 30px;
		color: #fff;

		@media screen and (max-width: 1024px) {
			border-radius: 15px 0px 0px 15px;

			&:first-child {
				border-radius: 0px 15px 15px 0px;
			}
		}

		.batterySpecBlockRow {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;
			padding-left: 15%;

			@media screen and (max-width: 500px) {
				padding-left: 1rem;
			}

			&:not(:first-child) {
				margin-top: 1rem;
			}
		}

		.batterySpecBlockIcon {
			color: #ffffff;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 40px;
			height: 40px;

			.enLeftIcon {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		.batterySpecContents {
			display: flex;
			flex-direction: column;
			margin-left: 1rem;

			.batterySpecBlockTitltle {
				font-weight: 400;
				font-size: 14px;
			}
			.batterySpecBlockSubTitltle {
				font-weight: 500;
				font-size: 12px;
			}
		}
	}

	.block3 {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		// background-image: url("../../assets/carDetail/backdrop/parking_lot.png");
		background-image: url("../../pages/Articles/images/main-eco-1.jpeg");
		box-shadow: 0px 6px 20px #00000040;
		border-radius: 30px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		padding: 30px;
		color: #fff;
		text-align: center;
		position: relative;
		overflow: hidden;
	}
	.block3::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// background-color: rgba(0, 0, 0, 0.5);
		background: rgb(0, 0, 0);
		background: linear-gradient(
			165deg,
			rgba(0, 0, 0, 1) 10%,
			rgba(0, 0, 0, 0.5956976540616247) 50%,
			rgba(0, 0, 0, 1) 90%
		);
		border-radius: inherit; /* Ensures the overlay matches the border radius of .block3 */
		z-index: 1; /* Places the overlay on top of the background image */
	}

	.block3 > * {
		position: relative;
		z-index: 2; /* Ensures the content is above the overlay */
	}
	@media screen and (max-width: 1024px) {
		.block3 {
			border-radius: 15px 0px 0px 15px;
		}
	}

	.block4 {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		min-height: 350px;
		background-image: url("../../assets/carDetail/backdrop/type-trye-size.png");
		box-shadow: 0px 6px 20px #00000040;
		border-radius: 30px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		padding: 30px;
		color: #fff;
		text-align: center;
	}

	.block4Center {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		min-height: 350px;
		background-image: url("../../assets/carDetail/backdrop/type-trye-size.png");
		box-shadow: 0px 6px 20px #00000040;
		border-radius: 30px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		padding: 30px;
		color: #fff;
		text-align: center;

		@media screen and (max-width: 1024px) {
			border-radius: 0;
			margin-bottom: -20px;
		}

		@media screen and (max-width: 500px) {
			border-radius: 0;
			margin-bottom: 0px;
		}
	}

	.tryeCarStyle {
		font-weight: 500;
		font-size: 18px;

		@media screen and (max-width: 800px) {
			font-size: 16px;
		}
		@media screen and (max-width: 500px) {
			font-size: 14px;
		}
	}

	.dimensionsRow {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		margin-top: 1.5rem;

		@media screen and (max-width: 800px) {
			margin: 1.5rem 0;
		}
	}

	.dimensionsBox {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-weight: 300;
		font-size: 16px;
		color: #fff;
		@media screen and (max-width: 800px) {
			font-weight: 400;
			font-size: 14px;
		}
	}

	.dimensionsValue {
		font-weight: 500;
		font-size: 32px;
		margin: 0 1rem;

		@media screen and (max-width: 800px) {
			font-weight: 500;
			font-size: 28px;
			margin: 0 0.8rem;
		}

		@media screen and (max-width: 400px) {
			font-size: 18px;
		}
	}
	.dimensionsUnit {
		font-weight: 300;
		font-size: 18px;
		text-align: center;

		@media screen and (max-width: 800px) {
			font-size: 15px;
		}

		@media screen and (max-width: 800px) {
			font-size: 12px;
		}
	}

	.blockTyre {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-flow: row wrap;
		@media screen and (max-width: 1024px) {
			margin-top: 5px;
			justify-content: center;
		}
	}

	.tryeOther {
		font-weight: 500;
		font-size: 16px;
		text-align: center;

		@media screen and (max-width: 800px) {
			font-weight: 500;
			font-size: 14px;
		}

		@media screen and (max-width: 500px) {
			font-size: 12px;
		}
	}

	.tryeConnectList {
		font-weight: 500;
		font-size: 16px;
		text-align: center;

		@media screen and (max-width: 1024px) {
			margin-top: 5px;
			text-align: center;
		}

		@media screen and (max-width: 500px) {
			font-size: 14px;
		}
	}
}
