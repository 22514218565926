.pageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2rem 0;

    .pageBox {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .pageEnd {
            margin: 1rem 0;
            width: 50%;
            height: 1px;
            background-color: #ddd;
        }

        .pageGoHome {
            background-color: #339f00;
            color: #fff;
            padding: 5px 1.5rem;
            border-radius: 10px;
        }
    }

    .pageImg {
        width: 400px;
        height: 400px;

        &.pageImgSm {
            width: 200px;
            height: 200px;
        }
        
        @media screen and (max-width: 900px) {
            width: 300px;
            height: 300px;
        }

        @media screen and (max-width: 700px) {
            width: 200px;
            height: 200px;
        }
    }

    .pageCode {
        font-size: 6rem;
        margin-top: -6rem;
        font-weight: 700;
        line-height: 1;
        color: #005930;
    }

    .pageDetail {
        font-size: 1.2rem;
        line-height: 1;
    }
}
