.inputContainer {
    display: flex;
    background-color: #fff;
    width: 40%;
    border-radius: 50px;
    min-width: 480px;
    position: relative;

    @media screen and (max-width: 950px) {
        width: 70%;
    }

    @media screen and (max-width: 700px) {
        min-width: 90%;
    }

    .formContainer {
        width: 100%;
        border-radius: 50px;
        position: relative;
        display: flex;
    }

    .inputBox {
        background-color: transparent;
        border: none;
        flex: 1;
        font-family: "Kanit", sans-serif;
        font-size: 1.3rem;
        text-align: center;
        border-radius: 50px 0 0 50px;
        padding: 0 90px 0 20px;
        height: 90px;

        @media screen and (max-width: 950px) {
            font-size: 1.2rem;
            height: 80px;
        }

        &,
        &:focus,
        &:hover,
        &:hover:focus {
            border: none;
            outline: none;
        }

        &::placeholder {
            font-weight: 300;
        }

        @media screen and (max-width: 700px) {
            padding: 0 70px 0 20px;
            font-size: 1rem;
            height: 70px;
        }
    }

    .inputBtnSection {
        position: absolute;
        padding: 10px;
        right: 0;
    }

    .inputBtnSearch {
        background-color: #339f00;
        border-radius: 50%;
        padding: 10px;
        color: #fff;
        font-size: 2rem;
        height: 70px;
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        cursor: pointer;

        &,
        &:focus,
        &:hover,
        &:hover:focus {
            border: none;
            outline: none;
        }

        @media screen and (max-width: 950px) {
            height: 60px;
            width: 60px;
            font-size: 1.5rem;
        }

        @media screen and (max-width: 700px) {
            height: 50px;
            width: 50px;
        }
    }
}
