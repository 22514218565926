.headerText {
    text-align: center;
    margin-top: 1rem;
    font-weight: 700;
    font-size: 100px;
    background: linear-gradient(180deg, #fff 0%, #000 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    letter-spacing: 1.5rem;
    line-height: 1;

    @media screen and (max-width: 900px) {
        font-size: 80px;
        margin: 1rem 0;
    }

    @media screen and (max-width: 900px) {
        font-size: 60px;
        letter-spacing: 1.2rem;
    }

    @media screen and (max-width: 550px) {
        font-size: 40px;
    }

    @media screen and (max-width: 450px) {
        font-size: 30px;
    }

    @media screen and (max-width: 450px) {
        font-size: 25px;
        letter-spacing: 1rem;
    }
}

.headerTextSecondary {
    text-align: center;
    margin: 1.5rem 0;
    font-weight: 700;
    font-size: 70px;
    background: linear-gradient(180deg, #fff 0%, #000 40px);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    letter-spacing: 1rem;
    line-height: 1;

    @media screen and (max-width: 900px) {
        font-size: 60px;
        margin: 1rem 0;
    }

    @media screen and (max-width: 900px) {
        font-size: 45px;
        letter-spacing: 0.8rem;
    }

    @media screen and (max-width: 550px) {
        font-size: 30px;
    }

    @media screen and (max-width: 450px) {
        font-size: 25px;
    }

    @media screen and (max-width: 450px) {
        font-size: 20px;
        letter-spacing: 1rem;
    }
}

.idText {
    font-size: 2rem;
}

.viewDetail {
    background-color: #339f00;
    padding: 1rem 3rem;
    border-radius: 50px;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
}

.ecoStickerImageBox {
    width: 100%;
    max-width: 700px;
    padding: 1.5rem;
    border-radius: 20px;
    box-shadow: 0 0 5px 2px #d7d7d7;
    margin-top: 2rem;
    min-height: 800px;
    transition-duration: .5s;
    position: relative;
    overflow: hidden;

    &.ecoStickerImageLoaded{
        min-height: 100%;
    }

    .ecoStickerOverImage{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #000a;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 1.5rem;
        transition-duration: .5s;

        &.ecoStickerImageLoaded{
            opacity: 0;
        }
    }

    .ecoStickerImage {
        width: 100%;
        height: 100%;
    }
}
