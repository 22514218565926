.stickerImageRoot {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;

	.stickerNotFoundBlock {
		display: flex;
		flex-direction: column;
		font-size: 20px;
		color: #eee;
	}

	.stickerImageBox {
		display: flex;
		flex-direction: column;
		padding: 3rem 0;
		height: 100vh;
		align-items: center;

		.ecoImage {
			height: 100%;
			width: 100%;
			object-fit: contain;
			border-radius: 10px;
		}

		.closeImage {
			width: fit-content;
			font-weight: 500;
			font-size: 16px;
			color: #fff;
			background-color: #000;
			padding: 0.5rem 2rem;
			border-radius: 10px;
			margin-top: 1rem;
			cursor: pointer;
			box-shadow: 0 0 10px #fff;
		}
	}

	.imageLoadingCover {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		transition-duration: 0.5s;
		font-size: 60px;
		color: #eee;

		&.loaded {
			display: none;
		}

		.coverText {
			font-size: 20px;
		}
	}
}
