.headerText {
    text-align: center;
    margin: 2rem 0;
    font-weight: 700;
    font-size: 100px;
    background: linear-gradient(180deg, #fff 0%, #000 64.06%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    letter-spacing: 1.5rem;

    @media screen and (max-width: 900px) {
        font-size: 80px;
        margin: 1rem 0;
    }

    @media screen and (max-width: 900px) {
        font-size: 60px;
        letter-spacing: 1.2rem;
    }

    @media screen and (max-width: 550px) {
        font-size: 40px;
    }

    @media screen and (max-width: 450px) {
        font-size: 30px;
    }

    @media screen and (max-width: 450px) {
        font-size: 25px;
        letter-spacing: 1rem;
    }
}

.headerLine {
    width: 200px;
    height: 15px;
    background: linear-gradient(65.52deg, #000000 9.01%, #ffffff 152.64%);
    border-radius: 20px;

    @media screen and (max-width: 450px) {
        width: 150px;
        height: 10px;
    }
}

.headContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.headOverride {
    border-bottom: 10px solid #000;
}

.pageContent {
    width: 100%;
    padding: 3rem 0 7rem;

    @media screen and (max-width: 900px) {
        padding: 2rem 0 5rem;
    }

    @media screen and (max-width: 750px) {
        padding: 1.5rem 0 5rem;
    }
}

.pageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.headerLabel {
    padding: 1.5rem 0;
    font-size: 2rem;

    @media screen and (max-width: 900px) {
        font-size: 1.5rem;
    }

    @media screen and (max-width: 450px) {
        font-size: 1.1rem;
    }
}

.contentDetail {
    font-size: 1.2rem;
    max-width: 750px;
    padding: 2rem 0;

    @media screen and (max-width: 900px) {
        font-size: 1rem;
    }
}

.contentRemark {
    font-weight: 300;
    max-width: 750px;

    @media screen and (max-width: 900px) {
        font-size: 0.9rem;
    }
}

.selectContainer {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 180px;

    @media screen and (max-width: 450px) {
        position: absolute;
        max-width: 150px;
        z-index: 3;
        top: 5px;
        left: 25px;
    }

    .selectFilter {
        width: 100%;
        position: relative;
        padding: 10px 10px 10px 20px;
        cursor: pointer;
        min-width: 150px;
        display: flex;
        align-items: center;
        overflow: hidden;
        background-color: #eee;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;

        @media screen and (max-width: 500px) {
            min-width: 140px;
            padding: 5px 5px 5px 15px;
        }

        @media screen and (max-width: 450px) {
            padding: 1px 5px;
            border-radius: 50px;
            background-color: #339f00;
        }

        .selectSelected {
            flex: 1;
            font-size: 1.1rem;
            font-weight: 500;
            text-align: right;

            @media screen and (max-width: 500px) {
                font-size: 0.9rem;
                font-weight: 400;
            }

            @media screen and (max-width: 450px) {
                font-size: 0.4rem;
                text-align: left;
                color: #fff;
            }
        }

        .selectBtnSection {
            padding: 0 10px;

            @media screen and (max-width: 450px) {
                padding: 0 5px;
                font-size: 0.5rem;
                color: #fff;
            }
        }
    }

    .selectOptionContainer {
        position: absolute;
        background-color: #eee;
        top: 60%;
        left: 0;
        width: 100%;
        padding-top: 36px;
        border-radius: 0 0 15px 15px;
        overflow: hidden;
        max-height: 320px;
        display: flex;

        @media screen and (max-width: 450px) {
            padding-top: 10px;
        }

        .selectOptionList {
            display: flex;
            flex-direction: column;
            max-height: 300px;
            overflow-y: auto;
        }

        .selectOptionItem {
            padding: 10px 20px;
            cursor: pointer;
            user-select: none;
            background-color: #fff;
            text-align: right;

            &.selected {
                background-color: #339f00;
                color: #fff;
            }

            @media screen and (max-width: 450px) {
                font-size: 0.4rem;
                padding: 2px 10px;
                text-align: left;
                background-color: #eee;
            }
        }

        .selectOptionItemNone {
            padding: 10px;
            user-select: none;
            text-align: center;
        }
    }

    .selectOpened {
        z-index: 9999;
    }
}
