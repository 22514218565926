.backgroundChargingType {
  padding: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 56px;
  align-items: start;
  position: relative;
  @media (max-width: 768px) {
    padding: 6px;
  }
}

.backgroundChargingType::before {
  content: "";
  background-image: url("../../assets/detailnextgenerationautomotive/background_charging_type.jpg");
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.65;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
