.tableCompare {
    display: grid;
    grid-gap: 0 4rem;
    justify-content: center;
    width: fit-content;

    &.tableCompareCol2 {
        grid-template-columns: repeat(2, 300px);
    }

    &.tableCompareCol3 {
        grid-template-columns: repeat(3, 300px);
    }

    &.tableCompareCol4 {
        grid-template-columns: repeat(4, 300px);
    }

    .tableCompareContent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 1rem;
        text-align: center;
        height: 100%;

        &.nonePadding{
           padding: 0 1rem; 
        }

        &.tableSpanCol2 {
            grid-column: span 2;
        }

        &.tableSpanCol3 {
            grid-column: span 3;
        }

        &.tableSpanCol4 {
            grid-column: span 4;
        }

        .tableCompareContentBox {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            font-weight: 500;

            .tableCompareContentSpan {
                margin: .5rem 0;
                white-space: normal;
            }

            .lSpan {
                font-size: 24px;

                @media screen and (max-width: 1000px) {
                    font-size: 20px;
                }
            }

            .mSpan {
                font-size: 20px;

                @media screen and (max-width: 1000px) {
                    font-size: 18px;
                }
            }

            .sSpan {
                font-size: 18px;

                @media screen and (max-width: 1000px) {
                    font-size: 16px;
                }
            }

            .dashSpan {
                height: 4px;
                width: 20px;
                background-color: #000;
                margin: 20px 0;
            }
        }
    }

    .tableCompareContentLabel {
        font-weight: 400;
        font-size: 0.9rem;
        color: #424245;
    }
}
