.loadingContainer {
    display: flex;
    justify-content: center;
    height: 120px;
    position: relative;
    padding: 1rem 0;

    .loadingContent {
        height: 100%;
    }

    .loadingDot {
        position: absolute;
        display: flex;
        justify-content: space-between;
        background-color: #ddd;
        z-index: 1;
        padding: 10px;
        border-radius: 7px;
        top: 10px;
        width: 70px;
        border: 3px solid #fff;

        & > div {
            height: 10px;
            width: 10px;
            background-color: #fff;
            border-radius: 50%;
            transition-duration: 0.3s;

            &:nth-of-type(1) {
                animation: ball-up-1 1s infinite linear;
            }

            &:nth-of-type(2) {
                animation: ball-up-2 1s infinite linear;
            }

            &:nth-of-type(3) {
                animation: ball-up-3 1s infinite linear;
            }

            @keyframes ball-up-1 {
                0% {
                    transform: translateY(0);
                }
                25% {
                    transform: translateY(-5px);
                }
                50% {
                    transform: translateY(0);
                }
            }
            @keyframes ball-up-2 {
                25% {
                    transform: translateY(0);
                }
                50% {
                    transform: translateY(-5px);
                }
                75% {
                    transform: translateY(0);
                }
            }
            @keyframes ball-up-3 {
                50% {
                    transform: translateY(0);
                }
                75% {
                    transform: translateY(-5px);
                }
                100% {
                    transform: translateY(0);
                }
            }
        }
    }

    .loadingText{
        position: absolute;
        top: 60px;
        padding: 5px 10px;
        background-color: #005930;
        color: #fff;
        border-radius: 7px;
    }
}
