// list car
.listBox {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    max-width: 280px;
    width: 100%;
    height: 100%;
    .listImgBox {
        position: relative;
        padding: 0 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 120px;

        @media screen and (max-width: 1200px) {
            height: 100px;
        }

        .listImg {
            height: 140px;
            width: 100%;
            object-fit: scale-down;
            overflow: hidden;
            border-radius: 20px;
        }
    }

    .listContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
        border-radius: 36px;
        margin-top: -70px;
        padding: 90px 22px 22px;
        height: 100%;

        // @media screen and (max-width: 1200px) {
        //     margin-top: -p0px;
        // }

        .listContentBrand {
            font-size: 1.2rem;
            font-weight: 500;
            text-align: center;

            @media screen and (max-width: 1000px) {
                font-size: 1rem;
            }
        }

        .listContentModel {
            font-size: 0.9rem;
            text-align: center;

            @media screen and (max-width: 1000px) {
                font-size: 0.6rem;
            }
        }

        .listContentPrice {
            font-weight: 300;
            text-align: center;
        }

        .listContentBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 10px;
            cursor: pointer;
            border-radius: 30px;
            line-height: 1;
            font-family: "Kanit", sans-serif;
            white-space: nowrap;

            &,
            &:focus,
            &:hover,
            &:hover:focus {
                border: none;
                outline: none;
            }

            &.listBtnDetail {
                color: #fff;
                background-color: #339f00;
            }

            &.listBtnCompare {
                color: #339f00;
                border: 1px solid #339f00 !important;
                background-color: #fff;

                & > i {
                    margin-right: 10px;
                }
            }
        }
    }
}
// list item when < 5 item
.listBoxSmall {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 30px;
    padding: 20px;

    .listBox_motor {
        display: flex;
        flex-direction: column;
        width: 280px;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        align-items: center;

        .listImgBox {
            width: 230px;
            border-radius: 20px;
            margin-top: -70px;
            img {
                border-radius: 20px;
                width: 100%;
                height: 150px;
                object-fit: cover;
                overflow: hidden;
            }
        }
        .listContent {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 20px;
            text-align: center;
            height: 100%;
            .listText {
                display: flex;
                flex-direction: column;
                .listContentBrand {
                    font-size: 1.2rem;
                    font-weight: 500;
                    text-align: center;
                }
                .listContentModel {
                    font-size: 0.9rem;
                    text-align: center;
                }
                .listContentPrice {
                    font-weight: 300;
                    text-align: center;
                }
                
            }
            .listContentBtn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                padding: 10px;
                cursor: pointer;
                border-radius: 30px;
                line-height: 1;
                font-family: "Kanit", sans-serif;
                white-space: nowrap;
    
                &,
                &:focus,
                &:hover,
                &:hover:focus {
                    border: none;
                    outline: none;
                }
    
                &.listBtnDetail {
                    color: #fff;
                    background-color: #339f00;
                }
    
                &.listBtnCompare {
                    color: #339f00;
                    border: 1px solid #339f00 !important;
                    background-color: #fff;
    
                    & > i {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
