.containerSection1 {
    display: flex;
    height: 100vh;
    width: 100%;
    padding-top: 190px;
    overflow-x: hidden;
    transition-duration: 1s;

    @media screen and (max-width: 1024px) {
        height: auto;
        min-height: 50vh;
        padding-top: 130px;
        // padding-top: 90px;
    }

    @media screen and (max-width: 500px) {
        padding-top: 0px;
        height: auto;
        min-height: fit-content;
    }

    .hasRemark {
        padding-top: 200px;
    }

    &.scrolled {
        height: 130px;
        overflow: hidden;
        padding-top: 0;

        &.hasRemark {
            height: 160px;
        }
    }
}

// car image viewer component

.containerViewer {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    flex-direction: column;

    .showingImageBox {
        width: 100%;
        height: calc(100% - 125px);
        // height: calc(100% - 100px);
        position: relative;
        transform: scale(0.95);

        @media screen and (max-width: 1024px) {
            // height: 40%;
            height: calc(100% * 0.5);
            min-height: 30vh;
            transform: scale(1);
        }

        @media screen and (max-width: 500px) {
            height: calc(100% - 160px);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .showingImage {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
        }

        .imageLoadingCover {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            transition-duration: 0.5s;
            font-size: 60px;
            color: #eee;

            &.loaded {
                background-color: transparent;
                background-image: linear-gradient(90deg, #fff 0%, transparent 2%, transparent 98%, #fff 100%);

                & > i {
                    display: none;
                }
            }
        }
    }

    .listImageBox {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0 0 20px 10px #fff;
        border-radius: 20px;

        @media screen and (max-width: 1024px) {
            position: static;
        }

        .nextSection {
            padding: 15px 0;
            font-size: 16px;
            width: fit-content;
            cursor: pointer;
            @media screen and (max-width: 500px) {
                padding: 20px 0;
            }

            @media screen and (max-width: 1024px) {
                display: none;
            }
        }
    }

    .remarkBlock {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        color: #fff;
        font-size: 14px;
        border-radius: 10px;
        background-color: #00000080;
        padding: 5px 10px;
        margin-bottom: 10px;

        .remarkTitle {
            font-weight: 500;
        }

        .remarkDetail {
            font-weight: 300;
            padding-left: 10px;
        }
    }

    .listImage {
        padding: 1rem;
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        background: #4a4a4a;
        border-radius: 20px;

        @media screen and (max-width: 1024px) {
            border-radius: 0;
        }

        @media screen and (max-width: 500px) {
            height: 60px;
            padding: 0.5rem;
        }

        .scrollContrainer {
            max-width: 100%;
            display: flex;

            .listImageItem {
                width: 160px;
                min-width: 160px;
                height: 100%;
                border-radius: 10px;
                background-color: #fff;
                overflow: hidden;
                cursor: pointer;
                position: relative;

                &:not(:last-child) {
                    margin-right: 1rem;
                }

                &.listImageItemActive {
                    border: 2px solid #339f00;
                }

                & img {
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                }

                @media screen and (max-width: 500px) {
                    max-width: 80px;
                    min-width: 80px;
                }

                .imageLoadingCover {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #e7e7e7bb;
                    transition-duration: 0.5s;
                    font-size: 18px;
                    color: #ddd;

                    &.loaded {
                        background-color: transparent;

                        & > i {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
