.compareBarContainer {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;

    &.compareBarOpen {
        padding-bottom: 2rem;
    }

    .compareBarBox {
        width: 100%;
        background: #000;
        box-shadow: 0px -20px 15px rgba(0, 0, 0, 0.1);
        border-radius: 30px;
        height: 150px;
        padding: 2rem;

        @media screen and (max-width: 900px) {
            display: grid;
            grid-template-columns: repeat(2, calc(50% - .5rem));
            grid-gap: 1rem;
            width: 100%;
            max-height: 100%;
            height: 100%;
            padding: 1rem;
        }
    }

    .compareBarItem {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        height: 100%;
        border-radius: 15px;
        background-color: #fff;
        position: relative;

        .compareBarItemRemove {
            position: absolute;
            text-align: center;
            right: 10px;
            top: 10px;
            font-size: 1.5rem;
            width: 20px;
            height: 20px;
            line-height: 1;
            cursor: pointer;

            @media screen and (max-width: 900px) {
                font-size: 1.2rem;
            }
        }

        @media screen and (max-width: 900px) {
            font-size: 0.8rem;
        }
    }

    .compareBarCommit {
        flex: 3;
        font-size: 1.5rem;
        font-weight: 600;
        background-color: #339f00;
        border-radius: 15px;
        padding: 10px;
        font-family: "Kanit", sans-serif;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        cursor: pointer;

        &,
        &:focus,
        &:hover,
        &:hover:focus {
            border: none;
            outline: none;
        }

        &:disabled {
            background-color: #818181;
        }

        @media screen and (max-width: 900px) {
            font-size: 1.2rem;
            grid-column: span 2;
            padding: 1rem;
            width: 100%;
        }
    }

    .compareBarBrand {
        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;

        @media screen and (max-width: 900px) {
            font-size: 1rem;
        }
    }

    .compareBarNo {
        font-size: 0.9rem;
        text-align: center;

        @media screen and (max-width: 900px) {
            font-size: 0.8rem;
        }
    }

    .compareBarBrandEmpty {
        font-size: 1.5rem;
    }

    .compareBarEmpty {
        font-size: 1.2rem;

        @media screen and (max-width: 900px) {
            font-size: 1rem;
        }
    }

    .compareBarCollapse {
        position: relative;
    }

    .compareBarCollapseClose {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        right: 10px;
        top: -20px;
        font-size: 2rem;
        width: 45px;
        height: 45px;
        color: #fff;
        border-radius: 50%;
        text-align: center;
        background-color: #ff0000;
        line-height: 1;
        cursor: pointer;

        @media screen and (max-width: 900px) {
            width: 40px;
            height: 40px;
            font-size: 1.5rem;
            right: 5px;
            top: -20px;
        }
    }
}
