.pageContent {
    width: 100%;
    padding: 3rem 0 7rem;
    position: relative;

    @media screen and (max-width: 750px) {
        padding: 1.5rem 0 5rem;
    }
}

.pageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    @media screen and (max-width: 1200px) {
        padding: 0 3rem;
    }

    @media screen and (max-width: 700px) {
        padding: 0;
    }

    .pageTop {
        display: flex;
        margin-left: -1rem;
        width: 100%;

        @media screen and (max-width: 1200px) {
            flex-direction: column;
        }

        .pageTopItem {
            display: flex;
            flex-direction: column;
            width: 50%;
            margin-left: 1rem;
            padding: 1rem;
            font-size: 2rem;
            line-height: 1;

            @media screen and (max-width: 1200px) {
                width: 100%;
            }

            @media screen and (max-width: 400px) {
                padding: 1rem 0;
            }

            &.center {
                align-items: center;
                justify-content: center;
            }
        }
    }

    .gradientText {
        font-weight: 700;
        font-size: 72px;
        background: linear-gradient(180deg, #fff 0%, #000 64.06%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        line-height: 1;

        @media screen and (max-width: 550px) {
            font-size: 45px;
        }
    }

    .tickTopText {
        font-size: 1.5rem;
        font-weight: 300;

        @media screen and (max-width: 550px) {
            font-size: 1.2rem;
        }

        .tickText {
            font-size: 2.2rem;
            font-weight: 400;

            @media screen and (max-width: 550px) {
                font-size: 1.8rem;
            }
        }
    }

    .switchContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;

        @media screen and (max-width: 1200px) {
            align-self: center;
            max-width: 500px;
        }

        @media screen and (max-width: 550px) {
            max-width: 300px;
        }

        .switchBackdrop {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;

            &::before {
                position: absolute;
                content: "";
                background-color: #f6f6f6;
                height: 4px;
                width: 100%;
                left: 0;
            }

            &::after {
                position: absolute;
                content: "";
                border-radius: 50%;
                background-color: #339f00;
                height: 20px;
                width: 20px;
                outline: 5px solid #e5e5e5;

                @media screen and (max-width: 550px) {
                    height: 15px;
                    width: 15px;
                    outline: 3px solid #e5e5e5;
                }
            }
        }

        .switchItem {
            background: #e5e5e5;
            box-sizing: border-box;
            border-radius: 40px;
            width: 200px;
            font-size: 1.8rem;
            z-index: 2;
            padding: 1rem;
            text-align: center;
            cursor: pointer;

            @media screen and (max-width: 1200px) {
                font-size: 1.2rem;
            }

            @media screen and (max-width: 550px) {
                width: 120px;
            }

            @media screen and (max-width: 400px) {
                font-size: 1rem;
                width: 100px;
            }

            &:hover {
                background: #ecf5e9;
            }

            &.switchActive {
                background: #ecf5e9;
                border: 3px solid #339f00;
                color: #339f00;

                @media screen and (max-width: 550px) {
                    border: 1px solid #339f00;
                }
            }
        }
    }

    .timelineContent {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        margin-top: 3rem;

        .timelineBackdrop {
            position: absolute;
            background-color: #f6f6f6;
            height: 100%;
            width: 4px;
            left: 50px;
        }
    }

    .timelineItem {
        display: flex;
        flex-direction: column;
        z-index: 2;

        &:not(:last-child) {
            margin-bottom: 3rem;
        }

        .timelineDate {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 1rem 1rem 1rem 45px;
            margin-top: 3rem;

            &.timelineReverse {
                margin-top: 0;
                margin-bottom: 3rem;
            }

            .timelineDot {
                border-radius: 50%;
                background-color: #339f00;
                height: 15px;
                width: 15px;
                outline: 5px solid #ebf6e6;
            }

            .timelineDateSpan {
                color: #828282;
                padding-left: 2rem;
                font-size: 1.5rem;

                @media screen and (max-width: 550px) {
                    font-size: 1.1rem;
                }
            }
        }

        .timelineItemContent {
            display: flex;
            background-color: #fff;

            @media screen and (max-width: 900px) {
                flex-direction: column;
            }

            .itemText {
                flex: 3;
                padding: 2.5rem 1.5rem 2.5rem 0;
                font-size: 1.6rem;
                font-weight: 300;

                @media screen and (max-width: 1024px) {
                    font-size: 1.2rem;
                }
                @media screen and (max-width: 900px) {
                    order: 2;
                }

                @media screen and (max-width: 550px) {
                    font-size: 1rem;
                }
            }

            .itemImg {
                flex: 2;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                @media screen and (max-width: 900px) {
                    order: 1;
                }

                .fadeUp {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    transition-duration: 0.3s;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #00000070;
                    color: #fff;
                    border-radius: 10px;
                    max-width: 390px;
                    max-height: 300px;
                    font-size: 1.3rem;
                    cursor: pointer;

                    &:hover {
                        opacity: 1;
                        transition-duration: 0.3s;
                    }
                }

                .itemImgContent {
                    max-width: 390px;
                    max-height: 300px;
                    border-radius: 10px;
                    overflow: hidden;
                    object-fit: contain;
                }
            }
        }
    }
}
.textBlue {
    color: #00c2ff;
}

.textGreen {
    color: #339f00;
}

.textRed {
    color: #f93f3f;
}
