.customizeBoxBanner {
  height: 70vh;
  width: 100%;
  position: relative;

  @media only screen and (max-width: 1440px) {
    // height: auto;
  }
}

.customizeBanner {
  height: 100%;
  width: 100%;
  position: relative;
  object-fit: cover;
}

.divFade {
  height: 150px;
  width: 100%;
  bottom: 0;
  position: absolute;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #fff 100%);
}

.customizeContentBanner {
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column !important;
  margin-top: -240px;

  @media only screen and (max-width: 576px) {
    margin-top: -95px;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    margin-top: -160px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    margin-top: -200px;
  }

  .customizeTitleBanner {
    text-align: center;
    color: #ffffff;
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: clamp(1rem, -0.3644rem + 5.8216vw, 4.875rem);
    @media only screen and (min-width: 1440px) {
      letter-spacing: 4px;
    }
  }

  .customizeDetailBanner {
    background-color: #ffffff;
    padding: 2.5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    border-radius: 15px;

    @media only screen and (max-width: 576px) {
      padding: 1rem;
    }

    .customizeSubTitleBanner {
      padding-left: 6rem;

      @media screen and (max-width: 576px) {
        padding-left: 1rem;
      }

      @media only screen and (min-width: 577px) and (max-width: 768px) {
        padding-left: 2rem;
      }

      @media only screen and (min-width: 769px) and (max-width: 1024px) {
        padding-left: 4rem;
      }
    }

    span {
      font-size: 20px;
      color: #000000;

      @media only screen and (max-width: 768px) {
        font-size: clamp(0.875rem, 0.5229rem + 1.5023vw, 1.875rem);
      }
    }
  }
}

.customizeBoxDescription {
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column !important;
  margin-top: 2rem;

  @media only screen and (max-width: 576px) {
    margin-top: 1rem;
  }

  .customizeTitleDescription {
    font-size: 40px;
    color: #333333;
    font-weight: 500;

    @media only screen and (max-width: 768px) {
      font-size: clamp(1.25rem, 0.8099rem + 1.8779vw, 2.5rem);
    }
  }
}

.customizeBoxDetail {
  text-indent: 50px;
  margin-top: 3rem !important;
  padding: 2rem !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;

  @media only screen and (max-width: 576px) {
    margin-top: 1rem !important;
    padding: 1rem !important;
  }
}

.customizeBoxDetailSecond {
  padding: 2rem !important;

  @media only screen and (max-width: 576px) {
    padding: 2rem 0 !important;
  }
}

.customizeDescription {
  
  font-size: 18px;
  color: #000000;

  @media only screen and (max-width: 768px) {
    font-size: clamp(0.875rem, 0.5229rem + 1.5023vw, 1.875rem);
  }
}

.imageTypeHEV {
  width: 100%;
  height: 292px;

  @media only screen and (max-width: 576px) {
    height: auto;
  }
}

.customizeBoxImage {
  padding: 1rem !important;

  @media only screen and (max-width: 576px) {
    padding: 0 !important;
  }
}

.image-container {
	width: 100%; /* Full width of the container */
  height: 450px;
  
}
.boxContentImage {
	width: 100%;
	display: flex;
	justify-content: center;
	align-self: center;
}
.imageTypeHEV {
	width: 100%; /* Make the image take the full width */
	height: 100%; /* Set height to fill the container */
	object-fit: cover; /* Ensures the image covers the container, maintaining aspect ratio */
}