.filterFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    padding-top: 1rem;

    .filterFooterBtn {
        background-color: #339f00;
        font-family: "Kanit", sans-serif;
        font-size: 1.5rem;
        padding: 0.5rem 3rem;
        color: #fff;
        border-radius: 10px;
        cursor: pointer;

        @media screen and (max-width: 700px) {
            font-size: 1.2rem;
            padding: 5px 1.8rem;
        }

        &,
        &:focus,
        &:hover,
        &:hover:focus {
            border: none;
            outline: none;
        }
    }
}

.filterItem {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;

    &:last-child {
        padding-bottom: 2.5rem;
    }

    .filterItemHeader {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 1rem;

        .filterItemHeaderText {
            background-color: #fff;
            padding-right: 10px;
            font-size: 1.5rem;
            z-index: 1;

            @media screen and (max-width: 500px) {
                font-size: 1.2rem;
            }
        }

        .filterItemHeaderLine {
            position: absolute;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #339f00;
        }
    }
}

.selectedListContainer {
    display: flex;
    flex-wrap: wrap;

    .selectedListItem {
        padding: 5px 15px;
        border: 1px solid #339f00;
        border-radius: 15px;
        background-color: #339f000a;
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 10px;
        margin-bottom: 10px;

        @media screen and (max-width: 500px) {
            font-size: 0.9rem;
        }

        &.selectedListItemWithIcon {
            padding-right: 25px;
        }
    }

    .selectedListRemove {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        text-align: center;
        right: 5px;
        width: 15px;
        height: 15px;
        line-height: 1;
        cursor: pointer;
    }
}

.selectContainer {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1.5rem;

    @media screen and (max-width: 500px) {
        flex-direction: column;
    }

    .selectedClear {
        color: #339f00;
        width: fit-content;
        padding: 0 15px;
        cursor: pointer;
        min-width: fit-content;

        @media screen and (max-width: 500px) {
            margin-left: auto;
            margin-top: 1rem;
        }

        &.selectedListItemEmpty {
            color: #a3a3a3;
            pointer-events: none;
        }
    }
}

.clearAllFilter {
    margin: 0 15px;
    cursor: pointer;
    color: #339f00;

    &.disabled {
        cursor: default;
        color: #a3a3a3;
        pointer-events: none;
    }
}

.selectFilterContainer {
    position: relative;
    width: 100%;
    max-width: 350px;

    @media screen and (max-width: 500px) {
        max-width: 100%;
    }

    .selectFilter {
        width: 100%;
        border-radius: 50px;
        position: relative;
        display: flex;
        padding: 10px 10px 10px 20px;
        border: 1px solid #e5e5e5;
        background-color: #fff;
        cursor: pointer;

        .selectSelected {
            flex: 1;
            font-size: 1.1rem;
            border: none;
            outline: none;
            font-family: "Kanit", sans-serif;

            @media screen and (max-width: 500px) {
                font-size: 0.9rem;
            }
        }

        .selectBtnSection {
            padding: 0 10px;
        }
    }

    .selectOptionContainer {
        position: absolute;
        background-color: #fff;
        top: 50%;
        left: 0;
        width: 100%;
        padding-top: 20px;
        border-radius: 0 0 15px 15px;
        overflow: hidden;
        max-height: 320px;
        border: 1px solid #e5e5e5;
        display: flex;

        .selectOptionList {
            display: flex;
            flex-direction: column;
            max-height: 300px;
            overflow-y: auto;
        }

        .selectOptionItem {
            padding: 10px 20px;
            cursor: pointer;
            user-select: none;

            &:not(:last-child) {
                border-bottom: 1px solid #ddd;
            }

            &:hover {
                background-color: #fff;
            }

            &.selected {
                background-color: #339f00;
                color: #fff;
            }
        }

        .selectOptionItemNone {
            padding: 10px;
            user-select: none;
            text-align: center;
        }
    }

    .selectOpened {
        z-index: 9999;
    }
}

.sliderContainer {
    display: flex;
    justify-content: center;
}
.sliderMain {
    position: relative;
    width: 90%;
    height: 20px;
    display: flex;
    align-items: center;

    .sliderRail {
        position: absolute;
        width: 100%;
        height: 5px;
        border-radius: 7px;
        cursor: pointer;
        background-color: #ddd;
        left: 0;
    }

    .sliderHandleContainer {
        width: 100%;
        position: absolute;
        height: 20px;
        display: flex;
        align-items: center;
        left: 0;

        .sliderHandleItem {
            position: absolute;
            z-index: 2;
            width: 15px;
            height: 15px;
            cursor: pointer;
            border-radius: 50%;
            box-shadow: 0 0 0 3px #0000001c;
            background-color: #339f00;
            margin-left: -6px;

            &:last-child {
                margin-left: -6px;
            }

            &.disabled {
                background-color: #ccc;
                cursor: default;
            }
        }
    }

    .sliderInnerContainer {
        width: 100%;
        position: absolute;
        height: 20px;
        display: flex;
        align-items: center;
        left: 0;

        .sliderTrack {
            position: absolute;
            height: 5px;
            z-index: 1;
            background-color: #339f0078;
            border-radius: 7px;
            cursor: pointer;

            &.disabled {
                background-color: #ccc;
            }
        }
    }

    .sliderOuterContainer {
        display: flex;
        height: 30px;
        width: 100%;
        align-items: flex-end;
        padding: 0 20px;

        .sliderTickItem {
            @media screen and (max-width: 700px) {
                & .sliderTickLabel,
                & .sliderTickLine {
                    opacity: 0;
                }

                &:first-child .sliderTickLabel,
                &:last-child .sliderTickLabel,
                &:first-child .sliderTickLine,
                &:last-child .sliderTickLine {
                    opacity: 1;
                }
            }

            .sliderTickLabel {
                position: absolute;
                font-size: 10px;
                text-align: center;
            }

            .sliderTickLine {
                position: absolute;
                margin-top: -8px;
                width: 1px;
                height: 5px;
                background-color: #ccc;
            }
        }
    }
}
.priceFilter {
    display: flex;
    flex-direction: column;

    span,
    input {
        font-family: "Kanit", sans-serif !important;
    }

    .stackRadio {
        @media screen and (max-width: 700px) {
            flex-wrap: wrap;
            margin-left: -24px;

            & > label {
                margin-left: 24px;
            }
        }

        .MuiFormControlLabel-label {
            @media screen and (max-width: 700px) {
                white-space: nowrap;
            }
        }
    }
}

.priceInputContainer {
    display: flex;
    padding-bottom: 2rem;
    align-items: flex-end;

    &.disabled {
        color: #ccc;
    }

    &.disabled .priceInput {
        border: 1px solid #ccc;
        color: #ccc;
    }

    .priceInputContent {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .priceInputLabel {
        padding: 0 15px 10px 15px;
    }

    .priceInput {
        width: 100%;
        margin: 0;
        padding: 10px 15px;
        border-radius: 20px;
        border: 1px solid #339f00;

        &:focus,
        &:hover {
            outline: none;
        }
    }

    .priceInputLine {
        padding: 5px 2rem;
        font-size: 1.5rem;

        @media screen and (max-width: 350px) {
            padding: 5px 1rem;
        }
    }
}

.datepickerInputLabel {
    color: #333333;
}
.datePickerInput {
    width: 100%;
    margin: 0;
    padding: 10px 15px;
    border-radius: 20px;
    border: 1px solid #e5e5e5;

    &:focus,
    &:hover {
        outline: none;
    }
}
