$background-color: white;
$font-primary-color: black;
$font-secondary-color: black;
$font-tertiary-color: #339F00;

$font-styles: "Kanit", sans-serif;

.justifyContentCenter{
    display: flex;
    justify-content: center;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

.anTitle{
    font-family: $font-styles;
    color: #000000;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 72px;
    letter-spacing: 0em;
    text-align: center;
    margin: 20px 0px 20px 0px;
}

.anDivider{
    display: flex;
    justify-content: center;
    height: 8px;
    // width: 580px;
    width: 40%;
    left: 430px;
    top: 292px;
    border-radius: 0px;
    background: #000000;
}

.anContainer{
    display: flex;
    flex-direction: row;
    justify-content: center; 
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
}

.anBlock{
    height: auto;
    width: 25%;
    border-radius: 10px;
    padding: 20px 25px 20px 25px;
    box-shadow: 0px 4px 20px 0px #0000000D;
    margin: 50px 20px 50px 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    // justify-content: center;
}

.anImage{
    width: 100%;
    height: auto;
}

.anBlockContent{
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    div{
        font-family: $font-styles;
        color: #000000;
        font-size: clamp(18px, 1.2vw, 18px);
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;

        &:hover{
            text-decoration: underline;
        }
    }
}

.anDate{
    font-family: $font-styles;
    color: #828282;
    // font-size: 14px;
    font-size: clamp(12px, 0.9vw, 14px);
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

.seeAll{
    font-family: $font-styles;
    color: #000000;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 40px;
}

.pageContent {
    width: 100%;
    padding: 4rem 2rem 17rem 2rem;

    @media screen and (max-width: 900px) {
        padding: 3rem 0 5rem;
    }

    @media screen and (max-width: 750px) {
        padding: 1.5rem 0 5rem;
    }
}


