.headContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.headOverride {
    border-bottom: 10px solid #000;
}

.pageContent {
    width: 100%;
    padding: 3rem 0 5rem;

    .pageTopItem {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        border-radius: 5px;
        color: #fff;
        width: 100%;
        text-align: center;
        padding: 10px 0;
        font-size: 1.5rem;
        position: relative;
        cursor: pointer;

        &.fontSuccess {
            background-color: #339f00;
            cursor: default;
        }

        & > i {
            position: absolute;
            left: 20px;
        }

        @media screen and (max-width: 900px) {
            font-size: 1.3rem;
        }

        @media screen and (max-width: 700px) {
            font-size: 1rem;
            max-width: 330px;
        }
    }
    .pageTopItemSwicth {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        border-radius: 5px;
        color: #fff;
        width: 100%;
        text-align: center;
        padding: 7px 0;
        font-size: 1.5rem;
        position: relative;
        cursor: pointer;

        &.fontSuccess {
            background-color: #339f00;
            cursor: default;
        }

        & > i {
            position: absolute;
            left: 20px;
        }

        @media screen and (max-width: 900px) {
            font-size: 1.3rem;
        }

        @media screen and (max-width: 700px) {
            font-size: 1rem;
            max-width: 330px;
        }
    }
}