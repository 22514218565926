.theme_funky{
    --cc-bg: #f9faff;
    --cc-text: #112954;
    --cc-btn-primary-bg: #3859d0;
    --cc-btn-primary-text: var(--cc-bg);
    --cc-btn-primary-hover-bg: #1d2e38;
    --cc-btn-secondary-bg: #dfe7f9;
    --cc-btn-secondary-text: var(--cc-text);
    --cc-btn-secondary-hover-bg: #c6d1ea;
    --cc-toggle-bg-off: #8fa8d6;
    --cc-toggle-bg-on: #3859d0;
    --cc-toggle-bg-readonly: #cbd8f1;
    --cc-toggle-knob-bg: #fff;
    --cc-toggle-knob-icon-color: #ecf2fa;
    --cc-block-text: var(--cc-text);
    --cc-cookie-category-block-bg: #ebeff9;
    --cc-cookie-category-block-bg-hover: #dbe5f9;
    --cc-section-border: #f1f3f5;
    --cc-cookie-table-border: #e1e7f3;
    --cc-overlay-bg: rgba(230, 235, 255, .85);
    --cc-webkit-scrollbar-bg: #ebeff9;
    --cc-webkit-scrollbar-bg-hover: #3859d0;
}

.theme_funky #c-ttl{
    color: var(--cc-btn-primary-bg);
}

/* Custom border radius */
.theme_funky #cm,
.theme_funky #s-bl .act .b-acc,
.theme_funky #s-inr,
.theme_funky .cc_div .b-tl,
.theme_funky .cc_div .c-bl{
    border-radius: 1.2em;
}

.theme_funky .cc_div .c-bn{
    border-radius: .7em;
}

.bold-text {
    font-weight: bold;
}