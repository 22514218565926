$background-color: white;
$font-primary-color: black;
$font-secondary-color: black;
$font-tertiary-color: #339f00;

$font-styles: "Kanit";

.justifyContentCenter {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 50px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.cardetailContainer {
  height: 100%;
  width: 100vw;
  background-color: $background-color;
}

%headerStyle {
  font-family: $font-styles;
  color: $font-secondary-color;
  background: -webkit-linear-gradient(#ffffff 0%, #000000 64.06%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.headerColor {
  color: $font-primary-color;
}

.approveDate {
  text-align: center;
  font-family: $font-styles;
  font-style: normal;
  font-weight: 300;
  font-size: clamp(18px, 15.5vw, 30px);
  padding-top: 15px;
}

.brand {
  @extend %headerStyle;
  font-size: clamp(48px, 5.5vw, 100px);
  font-weight: 700;
  line-height: 101.46px;
  display: block;
  letter-spacing: 30px;
  text-align: center;

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    letter-spacing: 25px;
    line-height: 80px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    letter-spacing: 15px;
    line-height: 70px;
  }

  @media only screen and (max-width: 321px) {
    letter-spacing: 10px;
    line-height: 50px;
  }
}

.headerDivier {
  display: flex;
  justify-content: center;
  height: 14px;
  width: 183px;
  left: 629px;
  top: 370px;
  border-radius: 23px;
  background: linear-gradient(65.52deg, #000000 9.01%, #ffffff 152.64%);
}

.model {
  @extend %headerStyle;
  text-align: center;
  font-size: clamp(28px, 4.29vw, 100px);
  font-weight: 700px;
  font-style: bold;
  line-height: 87.64px;

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    line-height: 60px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    line-height: 60px;
  }

  @media only screen and (max-width: 321px) {
    line-height: 60px;
  }
}

.generation {
  text-align: center;
  font-size: clamp(18px, 2.5vw, 48px);
  font-weight: 700px;
  font-style: bold;
}

.mainImage {
  width: 100%;
  max-width: 560px;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.subImageScrollbar {
  // width: 800px;
  // cursor: grab;
  // overflow-x: auto;
  // white-space: nowrap;
  display: flex;
  justify-content: center;
  margin: 0px 50px 0px 50px;
}

.subImage {
  // width: 20%;
  // height: auto;
  // border-radius: 10px;
  // margin: 5px;
  width: 100%;
  height: 100%;
  object-fit: cover;

  // height: '100%',
  // width: '100%',
  // objectFit: 'contain',
}

.ecoStickerLabel {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: $font-styles;
  font-size: clamp(12px, 2.5vw, 18px);
  height: 48.445343017578125px;
  width: 193px;
  border-radius: 10px;
  background: linear-gradient(65.52deg, #000000 9.01%, #ffffff 152.64%);
  margin: 50px 0px 50px 0px;
  // &:hover {
  //   background: linear-gradient(65.52deg, #000000 9.01%, gray 152.64%);
  // }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin: 20px 0px 20px 0px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    margin: 20px 0px 20px 0px;
  }

  @media only screen and (max-width: 321px) {
    margin: 20px 0px 20px 0px;
  }
}

.recommendedRetailPriceText {
  color: $font-secondary-color;
  font-family: $font-styles;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(12px, 3.5vw, 28px);
  text-align: center;
}

.recommendedRetailPriceNumber {
  color: $font-tertiary-color;
  font-family: $font-styles;
  font-style: normal;
  font-weight: 700;
  font-size: clamp(72px, 2.5vw, 96px);
  text-align: center;
  margin-bottom: 20px;
}

.remarkBlock {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px 0px 150px 0px;
}

.remarkTitle {
  font-family: $font-styles;
  font-style: bold;
  font-weight: 500;
  font-size: clamp(18px, 2.5vw, 24px);
}

.remarkDetail {
  font-family: $font-styles;
  font-style: normal;
  font-weight: 300;
  font-size: clamp(18px, 2.5vw, 24px);
  padding-left: 10px;
}

.contentTitle {
  text-align: center;
  font-family: $font-styles;
  color: #5f5f5f;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(18px, 2.5vw, 36px);
}

.contentDetail {
  text-align: center;
  font-family: $font-styles;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 60px;
  font-size: clamp(72px, 2.5vw, 100px);
  background: linear-gradient(180deg, #ffffff 0%, #000000 64.06%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @media only screen and (max-width: 900px) {
    margin-bottom: 20px;
  }
}

.environmentStandardTitle {
  color: $font-tertiary-color;
  font-family: $font-styles;
  font-style: normal;
  font-weight: 700;
  font-size: clamp(32px, 2.5vw, 64px);
  text-align: center;
  text-shadow: 0px 0px 9px rgba(255, 238, 0, 0.1);
  margin: 100px 0px 50px 0px;

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    margin: 30px 0px 30px 0px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin: 30px 0px 30px 0px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    margin: 30px 0px 30px 0px;
  }

  @media only screen and (max-width: 321px) {
    margin: 30px 0px 30px 0px;
  }
}

.environmentStandardActive {
  height: 245px;
  // width: 485px;
  width: 80%;
  border-radius: 25px;
  border: 6px solid #339f00;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
  box-shadow: 0px 0px 14px 10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 0px 14px 10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 0px 14px 10px rgba(0, 0, 0, 0.06);

  div {
    font-family: $font-styles;
    color: #339f00;
    font-size: clamp(32px, 2.5vw, 64px);
    font-style: normal;
    font-weight: 600;
    line-height: 58px;
    letter-spacing: 0em;
    text-align: center;
  }

  div {
    font-family: $font-styles;
    color: #339f00;
    font-size: clamp(16px, 2.5vw, 30px);
    font-style: normal;
    font-weight: 600;
    line-height: 58px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.environmentStandardInActive {
  height: 245px;
  width: 80%;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
  box-shadow: 0px 0px 14px 10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 0px 14px 10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 0px 14px 10px rgba(0, 0, 0, 0.06);

  div {
    font-family: $font-styles;
    color: #808080;
    font-size: clamp(32px, 2.5vw, 64px);
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
  }

  div {
    font-family: $font-styles;
    color: #808080;
    font-size: clamp(16px, 2.5vw, 30px);
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.approveStandardTitle {
  color: $font-tertiary-color;
  font-family: $font-styles;
  font-style: normal;
  font-weight: 700;
  font-size: clamp(32px, 2.5vw, 64px);
  text-align: center;
  text-shadow: 0px 0px 9px rgba(255, 238, 0, 0.1);
  margin: 80px 0px 50px 0px;

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    margin: 30px 0px 30px 0px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin: 30px 0px 30px 0px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    margin: 30px 0px 30px 0px;
  }

  @media only screen and (max-width: 321px) {
    margin: 30px 0px 30px 0px;
  }
}

.approveStandardBoxActive {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 29.45vh;
  width: 23%;
  margin: 25px;
  border-radius: 30px;
  background-color: #339f00;
  margin-bottom: 50px;

  box-shadow: 0px 0px 14px 10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 0px 14px 10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 0px 14px 10px rgba(0, 0, 0, 0.06);

  @media only screen and (max-width: 1600px) {
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    height: 22.45vh;
    width: 30%;
    margin: 20px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    height: 18.45vh;
    width: 30%;
    margin: 15px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    height: 18.45vh;
    width: 30%;
    margin: 10px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    height: 18.45vh;
    width: 30%;
    margin: 5px;
  }

  // @media only screen and (max-width: 321px) {

  // }

  div {
    color: white;
    font-family: $font-styles;
    font-style: normal;
    font-weight: 600;
    font-size: 1.79vw;
    text-align: center;
  }

  div {
    color: white;
    font-family: $font-styles;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5vw;
    text-align: center;
  }
}

.approveStandardContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px 100px 0px 100px;

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin: 0px 30px 0px 30px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    margin: 0px 10px 0px 10px;
  }
}

.approveStandardBoxInActive {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 29.45vh;
  width: 23%;
  margin: 25px;
  border-radius: 30px;
  background-color: #ffffff;
  margin-bottom: 50px;

  box-shadow: 0px 0px 14px 10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 0px 14px 10px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 0px 14px 10px rgba(0, 0, 0, 0.06);

  @media only screen and (max-width: 1600px) {
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    height: 22.45vh;
    width: 30%;
    margin: 20px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    height: 18.45vh;
    width: 30%;
    margin: 15px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    height: 18.45vh;
    width: 30%;
    margin: 10px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    height: 18.45vh;
    width: 30%;
    margin: 5px;
  }

  @media only screen and (max-width: 321px) {
  }

  div {
    color: #808080;
    font-family: $font-styles;
    font-style: normal;
    font-weight: 600;
    font-size: 2.34375vw;
    text-align: center;
  }

  div {
    color: #808080;
    font-family: $font-styles;
    font-style: normal;
    font-weight: 500;
    font-size: 1.953125vw;
    text-align: center;

    @media only screen and (max-width: 480px) {
      font-size: 3.24375vw;
    }
  }
}

.industryBlock {
  background-image: linear-gradient(270.83deg, #000000 -14.9%, rgba(0, 0, 0, 0) 99.13%),
    url("../assets/carDetail/unsplash_HNCSCpWrVJA.png");
  box-shadow: 0px 10px 25px 0px #00000040;
  border-radius: 30px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 71%;
  // margin: 5px 0;
  margin-top: 20px;
  padding: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin: 20px 30px 0px 30px;
    width: 85%;
    padding: 20px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    margin: 20px 10px 0px 10px;
    width: 85%;
    padding: 20px;
  }
}

.industryContentFlex {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.industryHeader {
  font-family: $font-styles;
  color: #ffffff;
  font-size: clamp(18px, 1.9vw, 100px);
  font-style: normal;
  font-weight: 600;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: center;

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    line-height: 44px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    line-height: 44px;
  }

  @media only screen and (max-width: 321px) {
    line-height: 44px;
  }
}

.industryName {
  font-family: $font-styles;
  color: #ffffff;
  font-size: clamp(14px, 2.8vw, 100px);
  font-style: normal;
  font-weight: 600;
  line-height: 8.75vw;
  letter-spacing: 0em;
  text-align: center;

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    line-height: 6.75vw;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    line-height: 6.75vw;
  }

  @media only screen and (max-width: 321px) {
    line-height: 6.75vw;
  }
}

.industryStartProduction {
  font-family: $font-styles;
  color: #c4c4c4;
  font-size: 1.9vw;
  font-size: clamp(12px, 1.9vw, 100px);
  font-style: normal;
  font-weight: 600;
  line-height: 4vw;
  letter-spacing: 0em;
  text-align: center;
}

.engineBlock {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 5px 0;

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
}

.enLeftBlock {
  background-image: linear-gradient(270.83deg, #000000 -14.9%, rgba(0, 0, 0, 0) 99.13%),
    url("../assets/carDetail/unsplash_xe-e69j6-Ds.png");
  box-shadow: 0px 10px 25px 0px #00000040;
  border-radius: 30px;
  background-position: left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 35%;
  margin: 0 5px;
  // position: relative;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 71%;
    object-fit: cover;
    margin-bottom: 5px;
    padding: 30px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 85%;
    object-fit: cover;
    margin-bottom: 5px;
    padding: 25px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 85%;
    object-fit: cover;
    margin-bottom: 5px;
    padding: 15px;
  }

  @media only screen and (max-width: 321px) {
    width: 85%;
    object-fit: cover;
    padding: 10px;
  }
}

.enLeftBlockRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 3px;
}

.enLeftBlockIcon {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 30%;
    justify-content: flex-end;
    margin-right: 30px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 30%;
    justify-content: flex-end;
    margin-right: 20px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 30%;
    justify-content: flex-end;
    margin-right: 20px;
  }
}

.enLeftContents {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: 5px;
}

.enLeftBlockTitltle {
  font-family: $font-styles;
  color: #ffffff;
  // font-size: 1.3vw;
  font-size: clamp(16px, 1.3vw, 42px);
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    // font-size: clamp(16px, 2.5vw, 42px);
    margin: 2px;
    line-height: 20px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    // font-size: clamp(16px, 2.5vw, 42px);
    margin: 0px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    // font-size: clamp(16px, 2.5vw, 42px);
    margin: 0px;
  }
}

.enLeftBlockSubTitltle {
  font-family: $font-styles;
  color: #ffffff;
  // font-size: 0.8vw;
  font-size: clamp(14px, 0.8vw, 100px);
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    // font-size: clamp(14px, 1.8vw, 100px);
    margin: 2px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    // font-size: clamp(14px, 1.8vw, 100px);
    margin: 0px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    // font-size: clamp(14px, 1.8vw, 100px);
    margin: 0px;
  }
}

.enRightBlock {
  background-image: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%),
    url("../assets/carDetail/unsplash_crs2vlkSe98.png");
  box-shadow: 0px 10px 25px 0px #00000040;
  border-radius: 30px;
  background-position: right;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 35%;
  min-height: 338px;
  position: relative;
  padding: 50px;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 71%;
    object-fit: cover;
    margin-bottom: 5px;
    padding: 30px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 85%;
    object-fit: cover;
    margin-bottom: 5px;
    padding: 25px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 85%;
    object-fit: cover;
    margin-bottom: 5px;
    padding: 15px;
  }

  @media only screen and (max-width: 321px) {
    width: 85%;
    object-fit: cover;
    padding: 10px;
  }
}

.enRightCarType {
  font-family: $font-styles;
  color: #ffffff;
  font-size: clamp(14px, 1vw, 100px);
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}

.enRightDimensionsFlex {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.enRightDimensionsRowTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  div {
    width: 30%;
    font-family: $font-styles;
    color: #ffffff;
    font-size: 0.9vw;
    font-size: clamp(14px, 0.9vw, 100px);
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0em;
    text-align: center;
  }
}

.enRightDimensionsRowMiddle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  div {
    width: 30%;
    font-family: $font-styles;
    color: #ffffff;
    // font-size: 1.8vw;
    font-size: clamp(14px, 1.8vw, 100px);
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0em;
    line-height: 30px;
    text-align: center;
  }

  div.enRightDimensionsMultiply {
    width: 5%;
    font-family: $font-styles;
    color: #ffffff;
    // font-size: 1.2vw;
    font-size: clamp(14px, 1.2vw, 100px);
    margin: 0px 5px 0px 5px;
  }
}

.enRightDimensionsRowBottom {
  font-family: $font-styles;
  color: #ffffff;
  // font-size: 0.9vw;
  font-size: clamp(14px, 0.9vw, 100px);
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0em;
  text-align: center;
}

.enRightBlockSpecs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;

  div {
    font-family: $font-styles;
    color: #ffffff;
    // font-size: 1.2vw;
    font-size: clamp(14px, 1.2vw, 100px);
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.batterySpecBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(270.83deg, #000000 -14.9%, rgba(0, 0, 0, 0) 99.13%),
    url("../assets/carDetail/unsplash_30D7430ywf4.png");
  box-shadow: 0px 10px 25px 0px #00000040;
  border-radius: 30px;
  background-position: left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 35%;
  // position: relative;
  padding: 50px;
  margin: 0 5px;

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 71%;
    object-fit: cover;
    margin-bottom: 5px;
    padding: 30px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 85%;
    object-fit: cover;
    margin-bottom: 5px;
    padding: 25px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 85%;
    object-fit: cover;
    margin-bottom: 5px;
    padding: 15px;
  }

  @media only screen and (max-width: 321px) {
    width: 85%;
    object-fit: cover;
    padding: 10px;
  }
}

.batterySpecBlockRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: 3px;
}

.batterySpecBlockIcon {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 30%;
    justify-content: flex-end;
    margin-right: 30px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 30%;
    justify-content: flex-end;
    margin-right: 20px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 30%;
    justify-content: flex-end;
    margin-right: 20px;
  }
}

.batterySpecContents {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.batterySpecBlockTitltle {
  font-family: $font-styles;
  color: #ffffff;
  // font-size: 0.8vw;
  font-size: clamp(14px, 0.8vw, 100px);
  font-style: normal;
  font-weight: 400;
  // line-height: 27px;
  letter-spacing: 0em;
}

.batterySpecBlockSubTitltle {
  font-family: $font-styles;
  color: #ffffff;
  // font-size: 1.1vw;
  font-size: clamp(14px, 1.1vw, 100px);
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
}

.fullWidthBlock {
  background-image: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%),
    url("../assets/carDetail/unsplash_crs2vlkSe98.png");
  box-shadow: 0px 10px 25px 0px #00000040;
  border-radius: 30px;
  background-position: right;
  // background-size: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  width: 70%;
  // max-height: 388px;
  // height: 338px;
  position: relative;
  margin: 5px 0;
  padding: 50px;
}

.fullWidthCarType {
  font-family: $font-styles;
  color: #ffffff;
  // font-size: 0.9vw;
  font-size: clamp(14px, 0.9vw, 100px);
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}

.fullWidthDimensionsFlex {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 10px;
  // background-color: #339F00;
}

.fullWidthDimensionsRowTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  div {
    // background-color: yellow;
    width: 30%;
    font-family: $font-styles;
    color: #ffffff;
    // font-size: 0.9vw;
    font-size: clamp(14px, 0.9vw, 100px);
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0em;
    text-align: center;
  }
}

.fullWidthDimensionsRowMiddle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  div {
    width: 30%;
    // background-color: blue;
    font-family: $font-styles;
    color: #ffffff;
    // font-size: 1.8vw;
    font-size: clamp(14px, 1.8vw, 100px);
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0em;
    line-height: 25px;
    text-align: center;
  }

  div.fullWidthDimensionsMultiply {
    width: 5%;
    font-family: $font-styles;
    color: #ffffff;
    // font-size: 1.2vw;
    font-size: clamp(14px, 1.2vw, 100px);
  }
}

.fullWidthDimensionsRowBottom {
  font-family: $font-styles;
  color: #ffffff;
  // font-size: 0.9vw;
  font-size: clamp(14px, 0.9vw, 100px);
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0em;
  text-align: center;
}

.fullWidthBlockSpecs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;

  div {
    font-family: $font-styles;
    color: #ffffff;
    // font-size: 1.2vw;
    font-size: clamp(14px, 1.2vw, 100px);
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.brochureBlock {
  background-image: url("../assets/carDetail/unsplash_dOG0z4-gqp0.png");
  border-radius: 25px 20px;
  box-shadow: 0px 10px 25px 0px #00000040;
  max-height: 1200px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 30px;
  width: 71%;
  margin-top: 5px;
  margin-bottom: 30px;

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 85%;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 85%;
  }

  @media only screen and (max-width: 321px) {
    width: 85%;
  }
}

.brochureContainer {
  display: flex;
  flex-direction: column;
  padding: 20x 30px 30px 30px;
  width: 100%;

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    padding: 10px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    padding: 10px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    padding: 10px;
  }

  @media only screen and (max-width: 321px) {
    padding: 10px;
  }
}

.brochureContents {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.brochureTitle {
  font-family: $font-styles;
  color: #ffffff;
  // font-size: 46px;
  font-size: clamp(14px, 6.1vw, 46px);
  font-style: normal;
  font-weight: 600;
  line-height: 134px;
  letter-spacing: 0em;
  text-align: center;

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    line-height: 134px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    line-height: 124px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    line-height: 104px;
  }

  @media only screen and (max-width: 321px) {
    line-height: 94px;
  }
}

.brochureOption {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 5px;
}

.brochureOptionIcon {
  display: flex;
  justify-content: center;
  color: #ffffff;
  width: 55%;
}

.brochureOptionTitle {
  font-family: $font-styles;
  color: #ffffff;
  // font-size: 25px;
  font-size: clamp(10px, 3.3vw, 23px);
  font-style: normal;
  font-weight: 600;
  // line-height: 45px;
  letter-spacing: 0em;
}

.brochureOptionList {
  display: flex;
  flex-direction: column;
  font-family: $font-styles;
  color: #ffffff;
  // font-size: 14px;
  font-size: clamp(10px, 1.8vw, 14px);
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px;

  li {
    list-style-type: none;
  }
}

.brochureSubTitle {
  font-family: $font-styles;
  color: #ffffff;
  // font-size: 30px;
  font-size: clamp(14px, 3.9vw, 30px);
  font-style: normal;
  font-weight: 600;
  line-height: 85px;
  letter-spacing: 0em;
  margin-top: 10px;
  text-align: center;
}

.brochureList {
  font-family: $font-styles;
  color: #ffffff;
  // font-size: 14px;
  font-size: clamp(14px, 1.8vw, 14px);
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  //   margin-left: 70px;
}

.downloadBrochureButton {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 68px;
    // width: 228px;
    width: 120%;
    height: 150%;
    border-radius: 10px;
    color: #ffffff;
    font-family: $font-styles;
    // font-size: 20px;
    font-size: clamp(10px, 2.6vw, 20px);
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    background: #0d6efd;
    &:hover {
      background: #0a54c1;
    }
  }
}

.optionTitle {
  font-family: $font-styles;
  color: #ffffff;
  // font-size: 46px;
  font-size: clamp(12px, 4.1vw, 46px);
  font-style: normal;
  font-weight: 600;
  // line-height: 134px;
  letter-spacing: 0em;
  text-align: center;
  padding: 10;
  margin: 10px 0px 10px 0px;

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    // line-height: 84px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    // line-height: 104px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    // line-height: 84px;
  }

  @media only screen and (max-width: 321px) {
    // line-height: 74px;
  }
}

.optionList {
  font-family: $font-styles;
  color: #ffffff;
  // font-size: 14px;
  font-size: clamp(12px, 1.8vw, 14px);
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  margin: 0px 30px 0px 30px;
}

.stickerImageRoot {
  display: flex;
  padding: 30px;
  .closeButtonWrapper {
    padding: 12px 0;

    i {
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: gainsboro;
      }
    }
  }
}

.stickerNotFoundBlock {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 20vh;
  width: 30vw;
  background-color: white;
  border-radius: 23px;
  color: black;

  span.notFoundText {
    // font-size: 1.6vw;
    font-size: clamp(14px, 1.6vw, 100px);
  }

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    i {
      cursor: pointer;
    }
  }
}
