@font-face {
	font-family: "ChatThai";
	src: url(../fonts/ChatThai.ttf) format("truetype");
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "CenturyGothicBold";
	src: url(../fonts/CenturyGothicBold.ttf) format("truetype");
	font-style: normal;
	font-weight: normal;
}

*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

html {
	-webkit-text-size-adjust: 100%;
}

body {
	/* font-family: CenturyGothicBold; */
	/* font-family: ChatThai; */
	font-size: 14px;
	color: #333;
	line-height: 1.42857143;
	margin: 0;
}

.font-container {
	font-family: ChatThai;
}
.sticker-container {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

.sticker-body-col {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
	width: 100%;
}

#car-body {
	position: relative;
	font-family: CenturyGothicBold;
	background-image: url("../images/main_sticker_PHEV_new.png");
	background-size: cover;
	image-rendering: pixelated;
	background-position: center;
	width: 21cm; /* A4 width */
	height: 29.7cm; /* A4 height */
	background-repeat: no-repeat;
	margin: 0;
	overflow: hidden;
}

#car-body-a5 {
	position: relative;
	font-family: CenturyGothicBold;
	background-image: url("../images/A5_main_sticker_PHEV.png");
	background-size: cover;
	image-rendering: pixelated;
	background-position: center;
	width: 21cm; /* A4 width */
	height: 29.7cm; /* A4 height */
	background-repeat: no-repeat;
	margin: 0;
	overflow: hidden;
}

.water-mark {
	z-index: 9999;
	position: absolute;
	top: 36%;
	right: -114px;
	color: red;
	font-size: 180px;
	font-weight: bold;
	white-space: nowrap;
	overflow: visible;
	opacity: 0.3;
	white-space: nowrap;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
}

.water-mark-qr {
	z-index: 9999;
	position: absolute;
	left: 55px;
	top: 39%;
	color: red;
	font-size: 24px;
	opacity: 0.4;
	font-weight: bold;
	overflow: visible;
	white-space: nowrap;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
}

.emission-container {
	position: relative;
	width: 93.75%;
	height: 233px;
	overflow: hidden;
	margin: 0 auto;
	padding-top: 26px;
}

.emissionCO2-block-new-version {
	text-align: center;
	position: absolute;
	width: 160px;
	font-size: 19pt;
	font-weight: bold;
	top: 220px;
	left: 133px;
}
.energy-consumption-block-new-version {
	text-align: center;
	position: absolute;
	width: 160px;
	font-size: 35pt;
	font-weight: bold;
	top: 108px;
	left: 45%;
}
.combined-energy-consumption-block-new-version {
	text-align: center;
	position: absolute;
	width: 70px;
	font-size: 18px;
	color: white;
	font-weight: bold;
	top: 228px;
	left: 48%;
}
.energy-urban-block-new-version {
	text-align: center;
	position: absolute;
	width: 160px;
	font-size: 24pt;
	font-weight: bold;
	top: 104px;
	right: 20px;
}
.energy-ex-urban-block-new-version {
	text-align: center;
	position: absolute;
	width: 160px;
	font-size: 24pt;
	font-weight: bold;
	top: 220px;
	right: 5px;
}

.emissionCO2-block {
	text-align: center;
	position: absolute;
	width: 160px;
	font-size: 19pt;
	font-weight: bold;
	top: 155px;
	left: 40px;
}

.energy-consumption-block {
    text-align: center;
    position: absolute;
    width: 146px;
    font-size: 35pt;
    font-weight: bold;
    top: 79px;
    left: 37%;
}

.combined-energy-consumption-block {
	text-align: center;
    position: absolute;
    width: 70px;
    font-size: 18px;
    color: white;
    font-weight: bold;
    top: 167px;
    left: 40.1%;
}

.distance-block {
	text-align: center;
	position: absolute;
	width: 160px;
	font-size: 18pt;
	font-weight: bold;
	top: 155px;
	right: 26px;
}
.distance-block-new-version {
	text-align: center;
	position: absolute;
	width: 160px;
	font-size: 18pt;
	font-weight: bold;
	top: 210px;
	right: 5px;
}

.energy-urban-block {
	text-align: center;
	position: absolute;
	width: 160px;
	font-size: 24pt;
	font-weight: bold;
	top: 102px;
	right: 20px;
}

.energy-ex-urban-block {
	text-align: center;
	position: absolute;
	width: 160px;
	font-size: 24pt;
	font-weight: bold;
	top: 160px;
	right: 20px;
}

.slider-container {
	position: relative;
    width: 646.5px;
    height: 36px;
    margin-top: 8px;
    margin-left: 75.5px;
    overflow: visible;
}

.slider-energy-block {
	position: absolute;
    background-position: center;
    background-size: 50px auto;
    -webkit-background-size: 50px auto;
    -moz-background-size: 50px auto;
    background-repeat: no-repeat;
    width: 30px;
    top: 5px;
    height: 100%;
    box-sizing: border-box;
}

.slider-energy-block img {
  position: absolute;
  display: block;
  max-width: 30px;
}
.slider-energy-text {
    width: 24px;
    text-align: center;
    position: relative;
    font-size: 9.9px;
    font-weight: bold;
    margin-top: 10px;
    margin-left: 3px;
}

.standard-section-container {
	position: relative;
}

.environment-standard-container {
	position: relative;
	margin-top: 62px;
	margin-left: 24px;
	width: 620px;
	height: 38px;
}

.environment-standard-container div.standard {
	display: inline-block;
	position: absolute;
	left: 33px;
	top: 2.5px;
}

.environment-standard-container div.euro4 {
	display: inline-block;
	position: absolute;
	left: 177px;
	top: 3px;
}

.environment-standard-container div.euro5 {
	display: inline-block;
	position: absolute;
	left: 329px;
	top: 3px;
}

.environment-standard-container div.euro6 {
	display: inline-block;
	position: absolute;
	left: 482px;
	top: 3px;
}

.security-standard-container {
	position: relative;
	margin-top: 31px;
	margin-left: 225px;
	width: 425px;
	height: 130px;
}

.brake-standard-container {
	position: relative;
	width: 524px;
	height: 45px;
}

.brake-standard-container div.abs {
	display: inline-block;
	position: absolute;
	left: 4px;
}

.brake-standard-container div.un_r13 {
	display: inline-block;
	position: absolute;
	left: 147px;
}

.brake-standard-container div.un_r13h {
	display: inline-block;
	position: absolute;
	left: 283px;
}

.passenger-standard-container {
	position: relative;
	margin-left: 138px;
	width: 524px;
	height: 45px;
}

.passenger-standard-container div.un_r94 {
	display: inline-block;
	position: absolute;
	left: 9px;
}

.passenger-standard-container div.un_r95 {
	display: inline-block;
	position: absolute;
	left: 145px;
}

.electric-standard-container {
	position: relative;
	margin-left: 142px;
	width: 160px;
	height: 45px;
}

.electric-standard-container div.un_r100 {
	display: inline-block;
	position: absolute;
	left: 5px;
}

.electric-standard-container div.un_r100tisi {
	display: inline-block;
	position: absolute;
	left: 141px;
}

.qr-container {
	position: absolute;
	width: 176px;
	top: 0px;
	right: 25px;
	height: 100%;
}

.qr-text-block {
	position: relative;
	margin-top: 11px;
	margin-left: 91px;
	font-size: 15px;
	color: white;
	font-weight: bold;
}

.qr-img-block {
	position: relative;
	left: 66px;
	top: 8px;
	width: 111px;
	height: 111px;
	padding: 1px;
}

.qr-img-block img.qr-logo {
	position: absolute;
	top: 40%;
	left: 32.5%;
	width: 35%;
}

.qr-img-block img {
	width: 100%;
}

.equipment-section-container {
	position: relative;
	margin: 0 auto;
	height: 628px;
	width: 793px;
	font-size: 17pt;
	font-family: ChatThai;
	word-break: break-word;
	line-height: 30px;
}

.basic-information-block {
	position: relative;
	top: 74px;
	left: 28px;
	width: 417px;
	height: 346px;
	overflow: hidden;
	font-weight: normal;
	font-size: 17px;
	line-height: 26px;
}

.importer-container {
	font-family: ChatThai;
	position: relative;
	top: 80px;
	left: 23px;
	height: 136px;
	width: 425px;
}

.importer-img-block {
	position: absolute;
    top: 45px;
    left: 19px;
    height: 80px;
    width: 80px;
}

.importer-img-block img {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 100px;
	height: 100px;
	object-fit: contain;
}

.importer-text-block {
	white-space: pre-wrap;
	display: flex;
	align-items: center;
	font-size: 16px;
	position: absolute;
	top: 35px;
	left: 134px;
	height: 102px;
	width: 291px;
	overflow: hidden;
	line-height: 20px;
}

.equipment-block {
	position: absolute;
	top: 75px;
	right: 179px;
	width: 308px;
	height: 419px;
	font-size: 15px;
	line-height: 20px;
}

.equipment-block ol {
	/* padding-top: 11px; */
	padding-left: 22px;
	margin-left: 154px;
	height: 414px;
	overflow: hidden;
	width: 100%;
}

ol li {
	/* white-space: pre-wrap; */
	white-space: pre-line;
}

.recommend-price-block {
	position: absolute;
	top: 83.5%;
	right: 22px;
	width: 309px;
	height: 42px;
	text-align: center;
	font-size: 19pt;
	font-family: CenturyGothicBold;
	overflow: hidden;
	font-weight: bold;
}

.recommend-price-block .price-suffix {
	font-family: ChatThai;
}

.remark-container {
	position: absolute;
	text-align: center;
	left: 43%;
	top: 6px;
	font-size: 12pt;
	color: white;
	font-family: ChatThai;
}

.fontwww {
	font-family: ChatThai;
	font-size: 13pt;
}
