.containerSection1 {
    display: flex;
    // height: 100vh;
    width: 100%;
    margin-top: 5rem;
    flex-direction: column;

    @media screen and (max-width: 800px) {
        margin-top: 1rem;
    }

    .topSection {
        display: flex;
    }

    .detailItem {
        font-size: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 140px;
        color: #000;
        @media screen and (max-width: 800px) {
            min-height: 100px;
        }

        @media screen and (max-width: 500px) {
            font-size: 14px;
            margin-bottom: 2rem;
            width: 200px;
        }
    }

    .detailItemTopic {
        text-align: center;
    }

    .detailItemValue {
        width: 100%;
        margin-top: auto;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        background: linear-gradient(180deg, #ffffff 0%, #000000 64.06%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        // color: #339f00;
        @media screen and (max-width: 800px) {
            font-size: 28px;
        }

        @media screen and (max-width: 500px) {
            font-size: 18px;
        }
    }

    .nonData {
        font-size: 36px;
        background: linear-gradient(180deg, #ffffff 0%, #000000 64.06%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        width: 100%;
        margin-top: auto;
        text-align: center;
        @media screen and (max-width: 800px) {
            font-size: 32px;
        }
    }

    .columnDetailContainer {
        width: 100%;
    }
    .columnDetailContainerSefty {
        width: 100%;
        @media screen and (max-width: 500px) {
            display: none;
        }
    }
    .columnDetailContainerSeftySm {
        display: none;
        @media screen and (max-width: 500px) {
            display: flex;

            & .columnDetailSeftySm {
                align-items: center;
            }
        }
    }

    .columnDetailContainerTopDetail {
        // width: 100%;
        @media screen and (max-width: 1024px) {
            padding: 0 20px 10px;
        }

        @media screen and (max-width: 500px) {
            overflow: auto;
            white-space: nowrap;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .columnDetail {
            width: 100%;
            background: linear-gradient(180deg, #fff0 0%, #eee 100%);
            border-radius: 30px;
            padding: 30px 15px;
            z-index: 1;

            @media screen and (max-width: 1024px) {
                margin-left: 0;
                background: #f5f5f5;
                position: relative;

                &:first-child {
                    margin-right: -30px;
                    padding-right: 45px;
                    margin-top: 20px;
                    margin-bottom: 20px;
                }

                &:last-child {
                    margin-left: -30px;
                    padding-left: 45px;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    z-index: 0;
                }
            }

            @media screen and (max-width: 500px) {
                background: transparent;
                margin: 0;

                &:first-child {
                    margin: 0;
                    padding-right: 15px;
                }

                &:last-child {
                    margin: 0;
                    padding-left: 15px;
                }
            }

            &.invert {
                background: linear-gradient(0deg, #fff0 0%, #eee 100%);

                @media screen and (max-width: 1024px) {
                    background: #fff;
                    box-shadow: 0px 0px 10px rgba(174, 174, 174, 0.25);
                }

                @media screen and (max-width: 500px) {
                    background: transparent;
                    box-shadow: none;
                    margin: 0;
                }
            }

            & .headerDivier {
                @media screen and (max-width: 500px) {
                    display: none;
                }
            }
        }
    }

    .headerDivier {
        display: flex;
        width: 100px;
        height: 5px;
        border-radius: 20px;
        background: linear-gradient(90deg, #090909 0%, #bababa59 100%);
        margin: 2rem 0;

        @media screen and (max-width: 500px) {
            margin: 1rem 0;
        }
    }

    .tisContainer {
        width: 100%;
        overflow: auto;
        white-space: nowrap;

        @media screen and (max-width: 1024px) {
            padding: 0 20px;
        }

        @media screen and (max-width: 500px) {
            display: grid;
            width: 100%;
            grid-template-columns: repeat(2, calc(50% - 10px));
            gap: 1rem;
            padding: 20px;

            & .tisItem {
                margin-left: 0;
                min-height: 100px;
            }
        }
    }

    .environmentStandardTitle {
        @media screen and (max-width: 1024px) {
            font-size: 36px;
        }
        color: #339f00;
        font-weight: 600;
        font-size: 40px;
    }

    .tisItem {
        width: 100%;
        color: #828282;
        padding: 15px;
        background-color: #fff;
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 15px 0 #eee;

        @media screen and (max-width: 1024px) {
            padding: 25px;
            border-radius: 10px;
            .tisItemTopic {
                font-weight: 400;
                font-size: 16px;
            }
            .tisItemValue {
                font-weight: 400;
                font-size: 14px;
            }
        }

        @media screen and (max-width: 500px) {
            height: 70px;
        }

        &.active {
            outline: 5px solid #339f00;
            outline-offset: -5px;
            color: #339f00;
            @media screen and (max-width: 500px) {
                outline: 3px solid #339f00;
                outline-offset: -3px;
            }
        }

        .tisItemTopic {
            @media screen and (max-width: 1024px) {
                font-weight: 400;
                font-size: 16px;
            }
            font-weight: 500;
            font-size: 30px;
        }

        .tisItemValue {
            @media screen and (max-width: 800px) {
                font-weight: 400;
                font-size: 14px;
            }
            font-weight: 400;
            font-size: 20px;
        }
    }

    .saftyItem {
        width: 100%;
        color: #fff;
        background: #49494980;
        border-radius: 30px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(5px);
        max-width: 270px;

        @media screen and (max-width: 1024px) {
            border-radius: 15px;
            max-width: 175px;
            padding: 25px;
        }

        @media screen and (max-width: 1024px) {
            padding: 2rem 1rem;
        }

        &.active {
            background: #fff;
        }

        &.active .saftyItemTopic {
            color: #339f00;
        }

        &.active .saftyItemValue {
            color: #000;
        }

        .saftyItemTopic {
            font-weight: 500;
            font-size: 30px;

            @media screen and (max-width: 1024px) {
                font-weight: 400;
                font-size: 16px;
            }
        }

        .saftyItemValue {
            font-weight: 400;
            font-size: 20px;

            @media screen and (max-width: 1024px) {
                font-weight: 400;
                font-size: 14px;
            }
        }
    }

    .saftyContainer {
        border-radius: 40px;
        overflow: hidden;
        box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);

        @media screen and (max-width: 1024px) {
            border-radius: 0;
            margin-bottom: 0;
            background-color: #000;
        }

        @media screen and (max-width: 500px) {
            // padding: 40px;
            width: 100%;
            text-align: center;
            padding: 4rem 2rem;
        }

        .approveStandardTitle {
            font-weight: 600;
            font-size: 36px;
            color: #fff;
            padding: 4rem 0;
            white-space: nowrap;

            @media screen and (max-width: 1024px) {
                font-weight: 500;
                font-size: 30px;
            }

            @media screen and (max-width: 500px) {
                font-weight: 500;
                font-size: 28px;
                padding: 2rem 0;
            }
        }
    }

    .ecoStickerLabel {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 18px;
        height: 48.445343017578125px;
        width: 193px;
        border-radius: 10px;
        background: linear-gradient(65.52deg, #000000 9.01%, #ffffff 152.64%);
    }

    .industryBlock {
        background-image: url("../../assets/carDetail/backdrop/year-model.png");
        box-shadow: 0px 6px 20px #00000040;
        border-radius: 30px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 30px;
        color: #fff;
        text-align: center;

        @media screen and (max-width: 1024px) {
            border-radius: 0;
            margin-top: 0;
            margin-bottom: 20px;
            background-color: #000;
        }

        // @media only screen and (min-width: 481px) and (max-width: 768px) {
        //   margin: 20px 30px 0px 30px;
        //   width: 85%;
        //   padding: 20px;
        // }

        // @media only screen and (min-width: 320px) and (max-width: 480px) {
        //   margin: 20px 10px 0px 10px;
        //   width: 85%;
        //   padding: 20px;
        // }

        .industryHeader {
            font-weight: 600;
            font-size: 28px;

            @media screen and (max-width: 800px) {
                font-weight: 500;
                font-size: 20px;
            }

            // @media only screen and (min-width: 481px) and (max-width: 768px) {
            //   line-height: 44px;
            // }

            // @media only screen and (min-width: 320px) and (max-width: 480px) {
            //   line-height: 44px;
            // }

            // @media only screen and (max-width: 321px) {
            //   line-height: 44px;
            // }
        }

        .industryName {
            font-weight: 600;
            font-size: 32px;
            padding: 2rem 0;

            @media screen and (max-width: 800px) {
                font-weight: 500;
                font-size: 22px;
                padding: 1.5rem 0;
            }

            // @media only screen and (min-width: 481px) and (max-width: 768px) {
            //   line-height: 6.75vw;
            // }

            // @media only screen and (min-width: 320px) and (max-width: 480px) {
            //   line-height: 6.75vw;
            // }

            // @media only screen and (max-width: 321px) {
            //   line-height: 6.75vw;
            // }
        }

        .industryStartProduction {
            @media screen and (max-width: 800px) {
                font-weight: 500;
                font-size: 16px;
            }
            font-weight: 500;
            font-size: 20px;
        }
    }

    .equipmentContainerSm {
        width: 100%;
        @media screen and (max-width: 500px) {
            flex-direction: column;

            & .equipmentOption {
                margin-left: 0;
                margin-top: 1rem;
                background-color: #ffffff17;
                padding: 1rem;
                border-radius: 1rem;
            }
        }
    }
    .equipmentDetailContainer {
        width: 100%;
        border-radius: 35px;
        overflow: hidden;
        min-height: 100px;
        padding: 2rem 5rem;
        color: #fff;

        @media screen and (max-width: 1024px) {
            margin-top: 20px;
            margin-bottom: 0;
            border-radius: 0;
        }

        @media screen and (max-width: 500px) {
            margin-top: 20px;
            padding: 15px 1rem;
        }

        .equipmentTitle {
            font-weight: 500;
            font-size: 32px;

            @media screen and (max-width: 1024px) {
                font-weight: 500;
                font-size: 28px;
            }
        }

        .equipmentOption {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .equipmentOptionIcon {
                width: 95px;
                height: 85px;
                margin-bottom: 1rem;

                @media screen and (max-width: 800px) {
                    width: 72px;
                    height: 70px;
                }

                & > img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .equipmentOptionList {
                display: flex;
                flex-direction: column;
                padding: 1rem 2rem;
                width: 100%;
                @media screen and (max-width: 500px) {
                    width: 350px;
                    flex-wrap: wrap;
                }

                & > div {
                    display: flex;

                    font-weight: 600;
                    font-size: 16px;
                    @media screen and (max-width: 800px) {
                        font-weight: 600;
                        font-size: 14px;
                    }
                }

                .equipmentOptionListText {
                    padding-left: 1rem;
                }

                .notfoundData {
                    justify-content: center;
                }
            }

            .equipmentOptionTitle {
                font-weight: 600;
                font-size: 26px;
                text-align: center;
                @media screen and (max-width: 800px) {
                    font-weight: 600;
                    font-size: 22px;
                }
            }
        }
        .optionTitle {
            @media screen and (max-width: 800px) {
                font-weight: 600;
                font-size: 20px;
            }
            font-weight: 600;
            font-size: 24px;
        }

        .optionList {
            display: flex;
            flex-direction: column;
            padding: 0 2rem;
            width: 100%;

            & > div {
                display: flex;
                font-weight: 600;
                font-size: 16px;
                @media screen and (max-width: 800px) {
                    font-weight: 600;
                    font-size: 14px;
                }
            }

            .optionListText {
                padding-left: 1rem;
            }
        }

        .downloadBrochur {
            display: flex;
            justify-content: center;
            padding: 2rem;

            .downloadBrochurButton {
                font-weight: 500;
                font-size: 16px;
                color: #fff;
                background-color: #000;
                padding: 1rem 2rem;
                border-radius: 10px;
                @media screen and (max-width: 800px) {
                    font-weight: 500;
                    font-size: 14px;
                }
            }
        }
    }
}
