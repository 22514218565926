.topicContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -56px;

    @media screen and (max-width: 950px) {
        margin-top: -51px

    };

    @media screen and (max-width: 700px) {
        margin-top: -42px

    };

    .topicBox {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000;
        position: relative;
        padding: 30px 60px;
        width: 40%;
        border-radius: 10px;
        min-height: 90px;
        min-width: 300px;

        @media screen and (max-width: 700px) {
            padding: 20px 40px;
            min-height: 60px;

        }
    }

    .topicLine {
        height: 10px;
        width: 100%;
        position: absolute;
        background-color: #339f00;
    }

    .topicText {
        height: 100%;
        width: 100%;
        background-color: #000;
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
        z-index: 2;
        color: #fff;
        padding: 0 1.5rem;
        min-width: fit-content;
        white-space: nowrap;

        @media screen and (max-width: 950px) {
            font-size: 1.5rem;
        }

        @media screen and (max-width: 450px) {
            font-size: 1.2rem;
        }
    }
}
