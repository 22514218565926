.navbarContainer {
    display: flex;
    width: 100%;
    pointer-events: none;
    border-radius: 30px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    &.navbarLoaded {
        pointer-events: all;
    }
    .containerBackdrop {
        border-radius: 0px 0px 30px 30px;

        @media screen and (max-width: 500px) {
            display: none;
        }
    }

    // &.topSectionHide {
    // }

    // @media screen and (max-width: 900px) {
    //     height: 85px;
    // }

    // @media screen and (max-width: 700px) {
    //     height: 65px;
    // }

    .topSection {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 1rem 0;
        background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.46875) 100%);
        max-height: 500px;
        overflow: hidden;
        transition-duration: 0.5s;

        @media screen and (max-width: 500px) {
            display: none;
        }

        &.topSectionHide {
            max-height: 0;
            padding: 0;
        }

        .navbarItemSide {
            display: flex;
            padding: 0 15px;
            height: 100%;

            &.left {
                justify-content: flex-end;
            }

            &.sm {
                align-items: center;
            }
        }
    }

    .middleSection {
        display: flex;
        flex-direction: column;
        background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        align-items: center;
        transition-duration: 0.5s;
        max-height: 0;
        opacity: 0;
        background-color: #000;

        @media screen and (max-width: 500px) {
            display: none;
        }

        &.middleSectionLoaded {
            max-height: 10px;
            opacity: 1;
        }

        &.middleSectionHide {
            display: none;
        }

        .middleLineSection {
            width: 350px;
            height: 5px;
            background: #fff;
            border-radius: 10px;
        }
    }

    .bottomSection {
        display: flex;
        flex-direction: column;
        background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        max-height: 0;
        align-items: center;
        transition-duration: 0.5s;
        opacity: 0;
        @media screen and (max-width: 500px) {
            display: none;
        }

        &.bottomSectionLoaded {
            max-height: 500px;
            opacity: 1;
            padding-bottom: 1rem;

            @media screen and (max-width: 1024px) {
                padding-top: 1rem;
            }

            @media screen and (max-width: 500px) {
                display: none;
            }
        }

        &.middleSectionHide {
            padding-top: 1rem;
        }

        .constainer {
            display: flex;
            width: 100%;
            padding: 0 30px;
            color: #fff;
            @media screen and (max-width: 500px) {
                display: none;
            }

            .containLeft {
                flex: 1;
                display: flex;
                flex-direction: column;

                .brand {
                    font-weight: 700;
                    font-size: 26px;
                }
                .model {
                    font-weight: 400;
                    font-size: 20px;
                }
            }

            .containRight {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .recommendedRetailPriceText {
                    font-size: 20px;
                    font-weight: 400;
                    text-align: right;
                }
                .recommendedRetailPriceNumberBox {
                    display: flex;
                    font-weight: 400;
                    font-size: 34px;

                    .recommendedRetailPriceNumber {
                        font-weight: 700;
                        color: #12c205;
                        margin-right: 1rem;
                    }
                }

                .approveDate {
                    font-weight: 300;
                    font-size: 18px;
                }
            }
        }

        .remarkBlock {
            display: flex;
            flex-direction: row;
            justify-content: center;
            color: #fff;
            font-size: 16px;
            border-radius: 10px;
            background-color: #000b;
            padding: 5px 10px;

            .remarkTitle {
                font-weight: 500;
            }

            .remarkDetail {
                font-weight: 300;
                padding-left: 10px;
            }
        }
    }

    .logoAfterHide {
        width: fit-content;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 0;
        max-width: 0;
        transition-duration: 0.5s;
        overflow: hidden;
        opacity: 0;

        @media screen and (max-width: 1024px) {
            max-width: fit-content;
            opacity: 1;
        }

        &.middleSectionHide {
            max-width: 150px;
            padding-right: 2rem;
            margin-top: 1rem;
            opacity: 1;
        }

        .navbarItemLogo {
            width: 90px;
            height: 50px;

            @media screen and (max-width: 900px) {
                width: 100px;
                height: 65px;
            }

            @media screen and (max-width: 700px) {
                width: 85px;
                height: 50px;
            }

            @media screen and (max-width: 500px) {
                width: 55px;
                height: 25px;
            }
        }
    }

    .navbarItemCenter {
        width: fit-content;
        height: 100%;
        padding: 0 3rem;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        @media screen and (max-width: 900px) {
            padding: 0 1rem;
        }

        &.sm {
            align-items: center;
        }

        .navbarItemLogo {
            width: 90px;
            height: 50px;

            @media screen and (max-width: 900px) {
                width: 100px;
                height: 65px;
            }

            @media screen and (max-width: 700px) {
                width: 85px;
                height: 50px;
            }
            @media screen and (max-width: 500px) {
                width: 60px;
                height: 35px;
            }
        }
    }

    .navbarItem {
        width: fit-content;
        height: fit-content;
        padding: 0 1rem 0.5rem;
        display: flex;
        align-items: flex-end;
        font-size: 18px;
        color: #fff;
        position: relative;

        &.active {
            color: #339f00;
            font-weight: 500;
        }

        &:not(:first-child):before {
            content: "";
            position: absolute;
            left: 0;
            top: 6px;
            width: 1px;
            height: 20px;
            background-color: #c4c4c4;
        }

        @media screen and (max-width: 900px) {
            font-size: 1rem;
        }

        @media screen and (max-width: 700px) {
            padding: 0 1.2rem 0.5rem;
        }
    }

    .active {
        border-bottom: 5px solid #828282;
    }

    .navbarMenu {
        padding: 2px 10px;
        width: fit-content;
        height: fit-content;
        border: 1px solid #339f00;
        color: #339f00;
        border-radius: 5px;
        background-color: #fff;
    }
}

.containerNavbarSm {
    flex-direction: column;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    // max-height: 0;
    height: 100px;
    padding: 20px;
    align-items: center;
    border-radius: 0;
    // transition-duration: 0.5s;
    opacity: 0;
    @media screen and (max-width: 500px) {
        display: flex;
    }
}
.containerBackdropSm {
    display: flex;
    flex-direction: column;
    display: none;
    height: 80px;

    @media screen and (max-width: 500px) {
        display: flex;
        height: 60px;
    }

    .navbarContainerSm {
        display: none;
        height: 80px;
        position: fixed;
        width: 100vw;
        top: 0;

        @media screen and (max-width: 500px) {
            display: flex;
            height: 60px;
            padding: 1rem;
        }

        .navbarItemSide {
            flex: 1;
            display: flex;
            align-items: flex-end;
            padding: 0 15px;
            height: 100%;

            &.left {
                justify-content: flex-end;
            }

            &.sm {
                align-items: center;
            }

            @media screen and (max-width: 500px) {
                padding: 0;
            }
        }

        .navbarItemCenter {
            width: fit-content;
            height: 100%;
            border-bottom: 5px solid transparent;
            padding: 0 3rem;
            display: flex;
            align-items: flex-end;
            justify-content: center;

            @media screen and (max-width: 900px) {
                padding: 0 1rem;
            }

            &.sm {
                align-items: center;
            }

            .navbarItemLogo {
                width: 65px;
            }

            @media screen and (max-width: 500px) {
                border-bottom: 0;
            }
        }

        .navbarItem {
            width: fit-content;
            height: fit-content;
            border-bottom: 5px solid transparent;
            padding: 0 2rem 1rem;
            display: flex;
            align-items: flex-end;
            font-size: 20px;
            color: #828282;
            position: relative;

            &.active {
                color: #339f00;
                font-weight: 500;
            }

            &:not(:first-child):before {
                content: "";
                position: absolute;
                left: 0;
                top: 6px;
                width: 1px;
                height: 20px;
                background-color: #c4c4c4;
            }

            @media screen and (max-width: 900px) {
                font-size: 1rem;
            }

            @media screen and (max-width: 700px) {
                padding: 0 1.2rem 1rem;
            }
        }

        .active {
            border-bottom: 5px solid #828282;
        }

        .navbarMenu {
            padding: 5px 10px;
            width: fit-content;
            height: fit-content;
            border: 1px solid #339f00;
            color: #339f00;
            border-radius: 5px;

            @media screen and (max-width: 500px) {
                padding: 2px 10px;
            }
        }
    }
}

.containerSm {
    display: none !important;

    @media screen and (max-width: 500px) {
        &.bottomSectionLoaded {
            display: flex !important;
        }
    }

    .containerDetailCarSm {
        padding: 70px 10px 10px 10px;

        .containLeft {
            width: 50%;
            flex: 1;
            display: flex;
            flex-direction: column;

            .brand {
                font-weight: 600;
                font-size: 18px;
            }
            .model {
                font-weight: 400;
                font-size: 14px;
            }
        }

        .containRight {
            width: 50%;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .recommendedRetailPriceText {
                font-size: 14px;
                font-weight: 400;
                text-align: right;
            }
            .recommendedRetailPriceNumberBox {
                display: flex;
                font-weight: 400;
                font-size: 18px;

                .recommendedRetailPriceNumber {
                    font-weight: 700;
                    color: #12c205;
                    margin-right: 1rem;
                }
            }

            .approveDate {
                font-weight: 300;
                font-size: 12px;
            }
        }
    }

    .remarkBlock {
        font-size: 14px;
        padding: 10px;
        font-weight: 300;

        .remarkTitle {
            font-weight: 500;
        }
    }
}
