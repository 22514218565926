.dev-img-bg {
	position: relative;
	text-align: center;
	color: #333333;
	height: 165vh;
}
.div-container-img-background {
	width: 100%;
	height: 60vh;
	object-fit: cover;
}
.div-img-background {
	width: 100%;
	height: 100%;
}
.div-text-bev {
	position: absolute;
	top: 40px;
	left: 80px;
	right: 80px;
}
.font-title-bev {
	font-weight: 500;
	font-size: 40px;
	text-align: start;
	padding: 0 4rem;
}
.font-bev {
	text-align: left;
	padding: 20px;
	font-size: 20px;
}
.card-text-rev {
	width: fit-content !important;
	height: auto !important;
	margin: 30px 38px;
	border-radius: 40px !important;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}
.div-img-grap {
	justify-content: center;
	display: flex;
	margin-top: 35px;
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
	.img-grap {
		width: 70%;
	}
	.font-bev {
		font-size: 18px;
	}
	.font-title-bev {
		font-size: 40px;
	}
	.card-text-rev {
		width: auto !important;
		margin: 30px 20px;
	}
	.div-text-bev {
		left: 20px !important;
		right: 20px !important;
	}
}
@media only screen and (max-width: 766px) {
	.card-text-rev {
		width: auto !important;
		margin: 30px 20px;
	}
	.font-bev {
		font-size: 14px;
		padding: 14px;
	}
	.div-text-bev {
		left: 20px;
		right: 20px;
	}
	.font-title-bev {
		font-size: 20px;
	}
	.img-grap {
		width: 90%;
	}
	.div-root-2 {
		min-height: unset;
	}
}
.background-main-phev {
	background-image: url("../../assets/detailnextgenerationautomotive/Group-phev.png");
	width: 100%;
	/* height: auto; */
	height: 60vh;
	background-size: cover; /* Ensures the image covers the entire box */
	background-position: center; /* Centers the image */
	background-repeat: no-repeat; /* Prevents the image from repeating */
	display: flex;
	align-items: center;
}

.span-title-section-phev {
	font-weight: 500;
	font-size: 40px;
	line-height: 48px;
	color: #fff;
	padding: 0 100px;
}

.main-box-phev {
	width: 100%;
	padding: 25px;
}

.card-section-phev {
	margin-top: 30px;
	background-color: #00000054 !important;
	border-radius: 16px !important;
}

.span-content-section-phev {
	font-size: 20px;
	font-weight: 400;
	line-height: 30px;
	color: #fff;
}

.card-section-phev .MuiCardContent-root {
	padding: 24px 40px;
}

@media only screen and (max-width: 768px) {
	.span-content-section-phev {
		font-size: 20px;
		font-weight: 400;
		line-height: 36px;
		color: #fff;
	}

	.span-title-section-phev {
		margin-left: 0px;
		display: flex;
		text-align: center;
	}
}

@media only screen and (max-width: 375px) {
	.span-content-section-phev {
		font-size: 18px;
		font-weight: 300;
		line-height: 36px;
		color: #fff;
	}

	.span-title-section-phev {
		margin-left: 0px;
		display: flex;
		text-align: center;
		font-size: 30px;
	}

	.card-section-phev .MuiCardContent-root {
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		padding: 24px 14px;
	}

	.container-custom-phev {
		padding: 0px;
	}
}
.card-content-bev {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.div-message-bev-helper {
	display: flex;
	justify-content: center;
	align-items: center;
}
.div-message-bev-helper-box {
	border: 2px solid #339f00;
	display: flex;
	padding: 20px 40px;
	width: 80%;
	border-radius: 10px;
	text-align: left;
	margin-top: 2rem;
}
.div-message-bev-helper-content {
	font-size: 20px;
	font-weight: 400;
	color: #333333;
	text-indent: 50px;
}

.div-content-image {
	width: 50%;
	overflow: hidden;
	border-radius: 10px;
}
.div-content-image-phev {
	width: 100%; 
	border-radius: 10px;
	overflow: hidden;
	margin-top: 30px;
}
.div-content-image-phev img {
	width: 100%;
	height: 100%;
}
.div-content-image img {
	width: 100%;
	height: 100%;
}
