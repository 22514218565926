@font-face {
  font-family: "ChatThai";
  src: url(../fonts/ChatThai.ttf) format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "CenturyGothicBold";
  src: url(../fonts/CenturyGothicBold.ttf) format("truetype");
  font-style: normal;
  font-weight: normal;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  /* font-family: CenturyGothicBold; */
  /* font-family: ChatThai; */
  font-size: 14px;
  color: #333;
  line-height: 1.42857143;
  margin: 0;
}

.font-container {
  font-family: ChatThai;
}
.sticker-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.sticker-body-col {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

#car-body {
  /* filter: grayscale(100%); */
  /* font-family: ChatThai; */
  font-family: CenturyGothicBold;
  background-image: url("../images/main_sticker.png");
  image-rendering: pixelated;
  position: relative;
  background-position: center;
  width: 1046.905512px;
  height: 1430.062992px;
  background-repeat: no-repeat;
  margin: 0px 0px 0px 0px;
}

.water-mark {
  z-index: 9999;
  position: absolute;
  top: 37%;
  color: red;
  font-size: 180px;
  font-weight: bold;
  white-space: nowrap;
  overflow: visible;
  opacity: 0.3;
  white-space: nowrap;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
}

.water-mark-qr {
  z-index: 9999;
  position: absolute;
  left: -12px;
  top: 40%;
  color: red;
  font-size: 36px;
  font-weight: bold;
  overflow: visible;
  opacity: 0.4;
  white-space: nowrap;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
}

.emissionCO2-block-new-version {
  text-align: center;
  position: absolute;
  width: 160px;
  font-size: 24pt;
  font-weight: bold;
  top: 220px;
  left: 133px;
}
.energy-consumption-block-new-version {
  text-align: center;
  position: absolute;
  width: 160px;
  font-size: 48pt;
  font-weight: bold;
  top: 108px;
  left: 45%;
}
.combined-energy-consumption-block-new-version {
  text-align: center;
  position: absolute;
  width: 70px;
  font-size: 25px;
  color: white;
  font-weight: bold;
  top: 228px;
  left: 48%;
}
.energy-urban-block-new-version {
  text-align: center;
  position: absolute;
  width: 160px;
  font-size: 24pt;
  font-weight: bold;
  top: 140px;
  right: 5px;
}
.energy-ex-urban-block-new-version {
  text-align: center;
  position: absolute;
  width: 160px;
  font-size: 24pt;
  font-weight: bold;
  top: 220px;
  right: 5px;
}

.emission-container {
  position: relative;
  width: 90.75%;
  height: 313px;
  overflow: hidden;
  margin: 0 auto;
  padding-top: 40px;
}

.emissionCO2-block {
  text-align: center;
  position: absolute;
  width: 160px;
  font-size: 24pt;
  font-weight: bold;
  top: 220px;
  left: 85px;
}

.energy-consumption-block {
  text-align: center;
  position: absolute;
  width: 160px;
  font-size: 48pt;
  font-weight: bold;
  top: 108px;
  left: 40%;
}

.combined-energy-consumption-block {
  text-align: center;
  position: absolute;
  width: 70px;
  font-size: 25px;
  color: white;
  font-weight: bold;
  top: 228px;
  left: 42.85%;
}

.energy-urban-block {
  text-align: center;
  position: absolute;
  width: 160px;
  font-size: 24pt;
  font-weight: bold;
  top: 140px;
  right: 55px;
}

.energy-ex-urban-block {
  text-align: center;
  position: absolute;
  width: 160px;
  font-size: 24pt;
  font-weight: bold;
  top: 220px;
  right: 55px;
}

.slider-container {
  position: relative;
  width: 646.5px;
  height: 36px;
  margin-top: 22px;
  margin-left: 73.5px;
  overflow: visible;
}

.slider-fuel-block {
  position: absolute;

  background-position: center;
  background-size: 50px auto;
  -webkit-background-size: 50px auto;
  -moz-background-size: 50px auto;
  background-repeat: no-repeat;
  width: 50px;
  height: 100%;
  box-sizing: border-box;
}

.slider-fuel-block img {
  position: absolute;
  display: block;
  max-width: 100%;
}
.slider-fuel-text {
  width: 24px;
  text-align: center;
  position: relative;
  font-size: 11.9px;
  font-weight: bold;
  margin-top: 4.5px;
  margin-left: 3.4px;
}

.standard-section-container {
  position: relative;
}

.environment-standard-container {
  position: relative;
  margin-top: 84px;
  margin-left: 46px;
  width: 800px;
  height: 50px;
  padding-top: 3.75px;
}

.environment-standard-container div.standard {
  display: inline-block;
  position: absolute;
  left: 50px;
}

.environment-standard-container div.euro4 {
  display: inline-block;
  position: absolute;
  left: 218px;
}

.environment-standard-container div.euro5 {
  display: inline-block;
  position: absolute;
  left: 415px;
}

.environment-standard-container div.euro6 {
  display: inline-block;
  position: absolute;
  left: 612px;
}

.security-standard-container {
  position: relative;
  margin-top: 43px;
  margin-left: 318px;
  width: 524px;
  height: 159px;
}

.brake-standard-container {
  position: relative;
  width: 524px;
  height: 56px;
}

.brake-standard-container div.abs {
  display: inline-block;
  position: absolute;
  left: 4px;
}

.brake-standard-container div.un_r13 {
  display: inline-block;
  position: absolute;
  left: 180px;
}

.brake-standard-container div.un_r13h {
  display: inline-block;
  position: absolute;
  left: 354px;
}

.passenger-standard-container {
  position: relative;
  margin-left: 176px;
  width: 524px;
  height: 58px;
}

.passenger-standard-container div.un_r94 {
  display: inline-block;
  position: absolute;
  left: 4px;
}

.passenger-standard-container div.un_r95 {
  display: inline-block;
  position: absolute;
  left: 178px;
}

.electric-standard-container {
  position: relative;
  margin-left: 180px;
  width: 160px;
  height: 45px;
}

.electric-standard-container div.un_r100 {
  display: inline-block;
  position: absolute;
}

.qr-container {
  position: absolute;
  width: 176px;
  top: 0px;
  right: 25px;
  height: 100%;
}

.qr-text-block {
  position: relative;
  margin-top: 15px;
  margin-left: 46px;
  font-size: 18px;
  color: white;
  font-weight: bold;
}

.qr-img-block {
  position: relative;
  left: 14px;
  top: 10px;
  width: 147px;
  height: 150px;
  padding: 1px;
}

.qr-img-block img.qr-logo {
  position: absolute;
  top: 40%;
  left: 32.5%;
  width: 35%;
}

.qr-img-block img {
  width: 100%;
}

.equipment-section-container {
  position: relative;
  top: 57px;
  margin: 0 auto;
  height: 628px;
  width: 951px;
  font-size: 17pt;
  /* font-size: 12pt; */
  font-family: ChatThai;
  word-break: break-word;
  line-height: 30px;
}

.basic-information-block {
  position: relative;
  top: 50px;
  left: 6px;
  width: 548px;
  height: 384px;
  overflow: hidden;
  font-weight: normal;
}

.importer-container {
  position: relative;
  top: 114px;
  left: 6px;
  height: 120px;
  width: 548px;
}

.importer-img-block {
  position: absolute;
  top: 13px;
  left: 14px;
  height: 100px;
  width: 100px;
}

.importer-img-block img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100px;
  height:100px;
  object-fit: contain;
}

.importer-text-block {
  white-space: pre-wrap;
  font-size: 23px;
  /* display: flex; */
  /* align-items: center; */
  position: absolute;
  top: 12px;
  left: 134px;
  height: 126px;
  width: 410px;
  overflow: hidden;
}

.equipment-block {
  position: absolute;
  top: 40px;
  right: 2.5px;
  width: 358px;
  height: 480px;
  font-size: 19px;
  line-height: 22px;
}

.equipment-block ol {
  margin:0;
  position: absolute;
  padding-top: 12px;
  padding-left: 27px;
  margin-left: -4px;
  height: 474px;
  overflow: hidden;
  width:100%;
}

ol li {
  white-space: pre-wrap;
}

.recommend-price-block {
  position: absolute;
  top: 93%;
  right: 4px;
  width: 358px;
  height: 50px;
  text-align: center;
  font-size: 23pt;
  font-family: CenturyGothicBold;
  overflow: hidden;
  font-weight: bold;
}

.recommend-price-block .price-suffix {
  font-family: ChatThai;
}

.remark-container {
  position: absolute;
  text-align: center;
  left: 43%;
  top: 18px;
  font-size: 12pt;
  color: white;
  font-family: ChatThai;
}

.fontwww {
  font-family: "Century Gothic";
  font-size: 13pt;
}
